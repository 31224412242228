import Button from "@/components/atoms/Button/button";
import BasicInfoElearning from "./basic-info";
import ContentElearning from "./content";
import {
  EContentRadioOptions,
  ICreateELearningForm,
  SelectOption,
  useCreateElearningForm,
} from "./validation";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_ROUTES_CONSTANT,
  ELEARNING_CONTENT_TYPE,
} from "@/configs/constants";
import {
  handleApiError,
  mapAPIError,
  scrollToFirstError,
  useScrollToFirstError,
} from "@/hooks/error";
import {
  useCheckCreateElearningCourseMutation,
  useCheckEditElearningCourseMutation,
  useCreateElearningCourseMutation,
  useEditElearningCourseMutation,
} from "@/api/e-learning";
import { useGlobalStore } from "@/states/global.state";
import dayjs from "dayjs";
import { DateFormat, ELEARNING_KIND_ENUM } from "@/configs/enum";
import useToast from "@/hooks/toast";
import { cutString } from "@/shared/transform";
import { TDetailELearning } from "@/api/type";
import { useEffect, useState } from "react";
import { isEmpty, isNull, isNumber } from "lodash";
import { CreateElearningRequestBody } from "@/api/e-learning/request";
import { combineArrays } from "./utils";
import useUpload, { UploadResponse } from "@/hooks/upload";
import Avatar from "@/components/atoms/Avatar/avatar";
import { getLinkMedia } from "@/shared/get";
import { useDisclosure } from "@chakra-ui/react";
import { ModalError } from "@/components/molecules/ModalError/modal-error";
import { v4 as uuidv4 } from "uuid";
import { FileItem, useFileStore } from "@/states/file.state";
import { createFileFromBlobURL } from "@/shared/transform/file";
import { MESSAGES } from "@/shared/validation/message";

const MAPPING_KEYS = {
  ["document"]: ELEARNING_KIND_ENUM.DOCUMENT,
  ["video"]: ELEARNING_KIND_ENUM.VIDEO,
  ["quiz"]: ELEARNING_KIND_ENUM.TEST,
  ["roleplaying_theme"]: ELEARNING_KIND_ENUM.ROLE_PLAYING,
  ["compliance"]: ELEARNING_KIND_ENUM.COMPLIANCE_TRAINING,
};

const MAPPING_LABELS = {
  ["document"]: ELEARNING_CONTENT_TYPE.DOCUMENT.label,
  ["video"]: ELEARNING_CONTENT_TYPE.VIDEO.label,
  ["quiz"]: ELEARNING_CONTENT_TYPE.TEST.label,
  ["roleplaying_theme"]: ELEARNING_CONTENT_TYPE.ROLE_PLAYING.label,
  ["compliance"]: ELEARNING_CONTENT_TYPE.COMPLIANCE_TRAINING.label,
};

export default function CreateElearningForm({
  course,
}: {
  course?: TDetailELearning;
}): JSX.Element {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const {
    handleSubmit,
    control,
    formState,
    setValue,
    reset,
    watch,
    setError,
    clearErrors,
    getValues,
  } = useCreateElearningForm();
  const { loading, setLoading, loadingFC, setLoadingFC } = useGlobalStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { uploadYoutubeFn } = useUpload();

  const checkCreateCourseMutation = useCheckCreateElearningCourseMutation();
  const createCourseMutation = useCreateElearningCourseMutation();
  const checkUpdateCourseMutation = useCheckEditElearningCourseMutation();
  const updateCourseMutation = useEditElearningCourseMutation();

  const [contentError, setContentError] = useState("");
  const [popupError, setPopupError] = useState("");

  const {
    reset: resetFileStore,
    files: filesStore,
    removeFile: removeFileStore,
  } = useFileStore();

  useEffect(() => {
    if (course) {
      const category =
        !course?.parent_category && !course?.sub_category
          ? undefined
          : {
              value: course?.parent_category?.id
                ? course?.parent_category?.id
                : course?.sub_category?.id,
              label: [course?.parent_category?.name, course?.sub_category?.name]
                .filter(Boolean)
                .join(" / "),
              parent_category_id: course?.parent_category?.id,
              sub_category_id: course?.sub_category?.id,
            };
      reset({
        title: course?.title,
        is_public: course?.is_public ? 1 : 0,
        required: course?.required ? [1] : [0],
        deadline_date: isNull(course?.deadline_date)
          ? null
          : new Date(course?.deadline_date?.substring(0, 10)),
        category: category,
        tags: course?.taggables?.map((tag) => ({
          value: tag.tag.id as number,
          label: tag.tag.name,
          ...(tag?.id && { id: tag?.id }),
        })),
        departments: course.all_department
          ? [{ value: "all", label: "全て" }]
          : course?.course_departments?.map((dep) => ({
              value: dep.department.id,
              label: dep.department.name,
              ...(dep?.id && { id: dep?.id }),
            })),
        offices: course?.all_office
          ? [{ value: "all", label: "全て" }]
          : course?.course_offices?.map((off) => ({
              value: off?.office?.id,
              label: off?.office?.name,
              ...(off?.id && { id: off?.id }),
            })),
        users: (course?.course_target_users || [])?.map((item) => {
          return {
            value: item?.user?.id,
            label: (
              <div className="flex items-center">
                <Avatar
                  url={getLinkMedia(
                    item?.user?.thumbnail?.key || item?.user?.avatar?.key,
                  )}
                  size="sm"
                />
                <div className="ml-2">
                  {item?.user?.first_name + " " + item?.user?.last_name}
                </div>
              </div>
            ),
            custom_field: item?.user?.first_name + " " + item?.user?.last_name,
            ...(item?.id && { id: item?.id }),
            office_id: item?.user?.office?.id || null,
            department_id: item?.user?.department?.id || null,
          };
        }),
        content: course.course_contents?.map((content) => {
          return {
            id: content?.id,
            child_id: uuidv4(),
            type: {
              value: MAPPING_KEYS[content?.kind as keyof typeof MAPPING_KEYS],
              label:
                MAPPING_LABELS[content?.kind as keyof typeof MAPPING_LABELS],
            },
            _destroy: false,
            ...(!isEmpty(content?.attachment) || !isEmpty(content?.youtube_id)
              ? {
                  attachment_attributes: content?.attachment,
                  document_id: null,
                  video_id: null,
                  option: 1,
                }
              : content.kind === "document"
              ? {
                  attachment_attributes: null,
                  document_id: {
                    value: content?.document?.id,
                    label: content?.document?.title,
                  } as SelectOption,
                  option: 0,
                }
              : {
                  attachment_attributes: null,
                  video_id: {
                    value: content?.video?.id,
                    label: content?.video?.title,
                  } as SelectOption,
                  option: 0,
                }),
            ...(content.kind === "quiz" && {
              test_id: {
                value: content?.quiz?.id,
                label: content?.quiz?.title,
              } as SelectOption,
            }),
            ...(content.kind === "roleplaying_theme" && {
              role_playing_title: content.roleplaying_theme,
            }),
            ...(content.kind === "compliance" && {
              compliance_training_id: {
                value: content?.compliance?.id,
                label: content?.compliance?.title,
              } as SelectOption,
            }),
            youtube_id: content.youtube_id || null,
            youtube_file_name: content.youtube_file_name || null,
          };
        }),
      });
    }
  }, [course]);

  const values = watch();

  const uploadYtb = (
    fileStore: FileItem,
    index: number,
    payload: CreateElearningRequestBody["course_contents_attributes"],
  ) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const newFile = await createFileFromBlobURL(
        fileStore.objectURL,
        fileStore.name,
        fileStore.type,
      );
      const response = await uploadYoutubeFn(newFile);

      if (response?.error) {
        // setValue(`content.${index}.youtube_id`, "");
        // setValue(`content.${index}.youtube_file_name`, "");
        setError(`content.${index}.youtube_id`, {
          message: MESSAGES.MSG_036,
        });

        return reject(false);
      }

      payload[index].youtube_id = response.youtube_id;
      payload[index].youtube_file_name = response.youtube_file_name;
      removeFileStore(fileStore.objectURL);
      resolve(true);
    });
  };

  const onSubmit = async (values: ICreateELearningForm) => {
    setContentError("");
    try {
      setLoading(true);
      const isSelectAllDepartment =
        values?.departments &&
        values?.departments?.findIndex((dep) => dep.value === "all") >= 0;
      const isSelectAllOffice =
        values?.offices &&
        values?.offices?.findIndex((off) => off.value === "all") >= 0;
      const isSelectAllUser =
        values?.users &&
        values?.users?.findIndex((off) => off.value === "all") >= 0;

      const payload: CreateElearningRequestBody = {
        title: values?.title,
        is_public: Boolean(values?.is_public),
        deadline_date: isNull(values.deadline_date)
          ? null
          : dayjs(values?.deadline_date).format(DateFormat.YYYY_DD_MM),
        parent_category_id: values?.category?.parent_category_id || null,
        sub_category_id: values?.category?.sub_category_id || null,
        all_department: isSelectAllDepartment || false,
        all_office: isSelectAllOffice || false,
        all_target_user: isSelectAllUser || false,
        required: values.required.includes(1) || false,
        taggables_attributes: values?.tags?.map((tag) => ({
          tag_id: tag.value as number,
        })),
        course_contents_attributes: values?.content?.map((content) => ({
          id: content?.id as number,
          kind: content.type?.value as number,
          ...(content.type?.value === ELEARNING_KIND_ENUM.DOCUMENT && {
            ...(content.option === EContentRadioOptions.TITLE
              ? {
                  document_id: content.document_id?.value,
                }
              : {
                  attachment_attributes:
                    content.attachment_attributes as UploadResponse,
                }),
          }),
          ...(content.type?.value === ELEARNING_KIND_ENUM.VIDEO && {
            ...(content.option === EContentRadioOptions.TITLE
              ? {
                  video_id: content.video_id?.value,
                }
              : {
                  youtube_id: content.youtube_id,
                  youtube_file_name: content.youtube_file_name,
                }),
          }),
          ...(content.type?.value === ELEARNING_KIND_ENUM.TEST && {
            quiz_id: content.test_id?.value,
          }),
          ...(content.type?.value === ELEARNING_KIND_ENUM.ROLE_PLAYING && {
            roleplaying_theme: content.role_playing_title || "",
            ...(content.option === EContentRadioOptions.TITLE
              ? {
                  video_id: content.video_id?.value,
                }
              : {
                  youtube_id: content.youtube_id,
                  youtube_file_name: content.youtube_file_name,
                }),
          }),
          ...(content.type?.value ===
            ELEARNING_KIND_ENUM.COMPLIANCE_TRAINING && {
            compliance_id: content.compliance_training_id?.value,
          }),
          ...(content._destroy && {
            _destroy: content._destroy,
          }),
        })),
        course_departments_attributes: isSelectAllDepartment
          ? []
          : values?.departments
              ?.filter((off) => off?.value !== "all")
              ?.map((department: any) => ({
                department_id: department.value,
              })) || [],
        course_offices_attributes: isSelectAllOffice
          ? []
          : values?.offices
              ?.filter((off) => off?.value !== "all")
              ?.map((office: any) => ({
                office_id: office.value,
              })) || [],
        course_target_users_attributes:
          values?.users
            ?.filter((user) => user?.value !== "all")
            ?.map((user) => ({
              user_id: user.value as number,
            })) || [],
      };

      if (course?.id) {
        const initTags = course?.taggables?.map((tag) => ({
          value: tag?.tag?.id,
          label: tag?.tag?.name,
          ...(tag?.id && { id: tag?.id }),
        }));
        const newTags = values?.tags.map((tag) => ({
          value: tag?.value,
          label: tag?.label,
          ...(tag?.id && { id: tag?.id }),
        }));

        const initOffices = course?.course_offices?.map((off) => ({
          value: off?.office?.id,
          label: off?.office?.name,
          ...(off?.id && { id: off?.id }),
        }));
        const newOffices =
          values?.offices?.map((off) => ({
            value: off?.value,
            label: off?.label,
            ...(off?.id && { id: off?.id }),
          })) || [];

        const initDepartments = course?.course_departments?.map((dep) => ({
          value: dep?.department?.id,
          label: dep?.department?.name,
          ...(dep?.id && { id: dep?.id }),
        }));
        const newDepartments =
          values?.departments?.map((dep) => ({
            value: dep?.value,
            label: dep?.label,
            ...(dep?.id && { id: dep?.id }),
          })) || [];

        const initUsers = course?.course_target_users?.map((user) => ({
          value: user?.user?.id,
          label: user?.user?.first_name + " " + user?.user?.last_name,
          ...(user?.id && { id: user?.id }),
        }));
        const newUsers =
          values?.users?.map((user) => ({
            value: user?.value,
            label: user?.custom_field || "",
            ...(user?.id && { id: user?.id }),
          })) || [];

        const updatePayload: CreateElearningRequestBody = {
          ...payload,
          id: course?.id,
          taggables_attributes: combineArrays(initTags, newTags).map(
            (item) => ({
              tag_id: item.value as number,
              id: item?.id,
              ...(item?._destroy && { _destroy: item?._destroy }),
            }),
          ),
          course_offices_attributes:
            combineArrays(initOffices, newOffices).map((item) => {
              return {
                office_id: item.value as number,
                id: item?.id,
                ...(item?._destroy && {
                  _destroy: item?._destroy,
                }),
              };
            }) || [],
          course_departments_attributes:
            combineArrays(initDepartments, newDepartments).map((item) => ({
              department_id: item.value as number,
              id: item?.id,
              ...(item?._destroy && { _destroy: item?._destroy }),
            })) || [],
          course_target_users_attributes:
            combineArrays(initUsers, newUsers).map((item) => ({
              user_id: item.value as number,
              id: item?.id,
              ...(item?._destroy && { _destroy: item?._destroy }),
            })) || [],
          course_contents_attributes:
            payload.course_contents_attributes
              .concat(values.deleted_content_ids as any)
              .filter(Boolean)
              .map((content: any) => {
                let tempAttachmentAttributes = content.attachment_attributes;
                const tempYoutubeAttributes = {
                  id: content.youtube_id || null,
                  name: content.youtube_file_name || null,
                };
                let videoId = content.video_id || content.video_id?.value;
                let documentId =
                  content.document_id || content?.document_id?.value;
                const initIndex = course?.course_contents?.findIndex(
                  (c) =>
                    c.id === content.id &&
                    ["video", "document", "roleplaying_theme"].includes(c.kind),
                );
                const oldCourse = course?.course_contents?.find(
                  (c) => c.id === content.id,
                );
                const resetFields = {
                  document_id: null,
                  attachment_attributes:
                    (oldCourse?.attachment && {
                      ...oldCourse?.attachment,
                      _destroy: true,
                    }) ||
                    null,
                  video_id: null,
                  quiz_id: null,
                  roleplaying_theme: null,
                  compliance_id: null,
                };

                if (content?.id && isNumber(content?.id)) {
                  if (initIndex > -1) {
                    // CASE: CHANGE FROM VIDEO_ID/DOCUMENT_ID TO ATTACHMENT_ATTRIBUTES
                    if (
                      !isEmpty(
                        course?.course_contents?.[initIndex]?.attachment,
                      ) &&
                      isEmpty(content?.attachment_attributes)
                    ) {
                      tempAttachmentAttributes = {
                        ...course.course_contents?.[initIndex]?.attachment,
                        _destroy: true,
                      };
                    }

                    // CASE: CHANGE FROM ATTACHMENT_ATTRIBUTES TO VIDEO_ID/DOCUMENT_ID
                    if (
                      isEmpty(
                        course?.course_contents?.[initIndex]?.attachment,
                      ) &&
                      !isEmpty(content?.attachment_attributes)
                    ) {
                      if (content.kind === 1 || content?.type?.value === 1) {
                        documentId = null;
                      } else {
                        videoId = null;
                      }
                    }
                  }
                }

                // merge 2 types of array
                return {
                  kind: content.kind || content.type.value,
                  ...((content.kind === 1 || content?.type?.value === 1) && {
                    ...resetFields,
                    document_id: documentId,
                  }),
                  ...((content.kind === 2 || content?.type?.value === 2) && {
                    ...resetFields,
                    video_id: videoId,
                  }),
                  ...((content.kind === 3 || content?.type?.value === 3) && {
                    ...resetFields,
                    quiz_id: content.quiz_id || content?.test_id?.value,
                  }),
                  ...((content.kind === 4 || content?.type?.value === 4) && {
                    ...resetFields,
                    roleplaying_theme:
                      content.roleplaying_theme || content?.role_playing_title,
                    video_id: videoId,
                  }),
                  ...((content.kind === 5 || content?.type?.value === 5) && {
                    ...resetFields,
                    compliance_id:
                      content.compliance_id ||
                      content?.compliance_training_id?.value,
                  }),
                  ...([1].includes(content.kind || content?.type?.value) && {
                    attachment_attributes: tempAttachmentAttributes,
                  }),
                  ...([2, 4].includes(content.kind || content?.type?.value) && {
                    youtube_id: tempYoutubeAttributes.id,
                    youtube_file_name: tempYoutubeAttributes.name,
                    video_id: videoId || null,
                  }),
                  ...(content.id &&
                    isNumber(content.id) && { id: content?.id }),
                  ...(content._destroy && { _destroy: content?._destroy }),
                };
              }) || [],
        };

        // handle precheck validation
        await checkUpdateCourseMutation.mutateAsync(updatePayload);

        // handle upload ytb
        const promises: any[] = [];
        updatePayload.course_contents_attributes.forEach((content, index) => {
          if (content.youtube_id) {
            const ytbId = content.youtube_id;
            const isLinkLocal = ytbId?.startsWith("blob:");
            if (isLinkLocal) {
              const fileStore = filesStore.find((i) => i.objectURL === ytbId);
              if (fileStore) {
                promises.push(
                  uploadYtb(
                    fileStore,
                    index,
                    updatePayload.course_contents_attributes,
                  ),
                );
              }
            }
          }
        });

        let isUploadFail = false;
        await Promise.all(promises)
          .then()
          .catch(() => {
            isUploadFail = true;
          });

        if (isUploadFail) return;

        await updateCourseMutation.mutateAsync(updatePayload);
      } else {
        // handle precheck validation
        await checkCreateCourseMutation.mutateAsync(payload);

        // handle upload ytb
        const promises: any[] = [];

        payload.course_contents_attributes.forEach((content, index) => {
          if (content.youtube_id) {
            const ytbId = content.youtube_id;
            console.log(ytbId);

            const isLinkLocal = ytbId?.startsWith("blob:");
            console.log(isLinkLocal);
            if (isLinkLocal) {
              const fileStore = filesStore.find((i) => i.objectURL === ytbId);
              if (fileStore) {
                promises.push(
                  uploadYtb(
                    fileStore,
                    index,
                    payload.course_contents_attributes,
                  ),
                );
              }
            }
          }
        });

        let isUploadFail = false;
        await Promise.all(promises)
          .then()
          .catch(() => {
            isUploadFail = true;
          });

        if (isUploadFail) return;
        await createCourseMutation.mutateAsync(payload);
      }

      showToast({
        title: `${cutString(values?.title)}${
          course?.id ? "を保存しました" : "を作成しました"
        }`,
        type: "success",
      });
      navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_LIST);
    } catch (errRes: any) {
      const errorTitle =
        errRes?.response?.data.error_message?.title?.join("\n");
      const err = handleApiError(errRes);
      mapAPIError(err, setError, (e) => {
        let path = e.path;
        if (e.path === "parent_category") {
          path = "category";
        }
        if (e.path === "sub_category") {
          path = "category";
        }
        if (e.path === "course_offices.office") {
          path = "offices";
        }
        if (e.path === "course_departments.department") {
          path = "departments";
        }
        if (e.path === "course_target_users.user") {
          path = "users";
        }
        if (e.path === "taggables.tag") {
          path = "tags";
        }
        if (e.path === "popup") {
          onOpen();
          setPopupError(e.message);
        }
        if (e.path === "base") {
          setContentError(e.message);
        }
        setError(path, {
          message: e.message,
        });

        if (e.path === "taggables" && Array.isArray(e?.message)) {
          setError("tags" as any, {
            message: e?.message?.[0]?.message,
          });
        }
        if (e.path === "course_contents" && Array.isArray(e?.message)) {
          e?.message?.map((item: any) => {
            let childKeyName = item.child_key;
            if (item.child_key === "document") {
              childKeyName = "document_id";
            }
            if (item.child_key === "video") {
              childKeyName = "video_id";
            }
            if (item.child_key === "quiz") {
              childKeyName = "test_id";
            }
            if (item.child_key === "compliance") {
              childKeyName = "compliance_training_id";
            }
            if (item.child_key === "roleplaying_theme") {
              childKeyName = "role_playing_title";
            }
            const path = `content.${item.index}.${childKeyName}`;
            setError(path as any, {
              message: item.message,
            });
          });
        }
        if (e.path === "title" && errorTitle.length) {
          setError(path, {
            message: errorTitle,
          });
        }
      });
      scrollToFirstError();
    } finally {
      setLoading(false);
    }
  };

  const { errors } = formState;

  useScrollToFirstError(formState);

  const onCloseModalError = () => {
    onClose();
    setPopupError("");
  };

  const handleCancel = async () => {
    navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.ELEARNING_LIST);
  };

  useEffect(() => {
    return () => {
      resetFileStore();
    };
  }, []);

  useEffect(() => {
    if (loading.open !== loadingFC.open) {
      setLoadingFC(loading.open, "保存中...");
    }
  }, [loading.open, loadingFC.open]);

  return (
    <div>
      <BasicInfoElearning
        control={control}
        errors={errors}
        values={values}
        setValue={setValue}
        contentError={contentError}
      />
      <ContentElearning
        control={control}
        errors={errors}
        values={values}
        setValue={setValue}
        reset={reset}
        setError={setError}
        clearErrors={clearErrors}
        getValues={getValues}
      />

      <div className="flex gap-[10px] mt-8 justify-end">
        <Button
          size="sm"
          variant="info"
          className="min-w-[128px] w-fit"
          buttonCustom={{
            padding: "12px 23px",
          }}
          onClick={handleCancel}
          isDisabled={loading?.open}
        >
          キャンセル
        </Button>

        <Button
          size="sm"
          className="min-w-[128px] w-fit"
          buttonCustom={{
            padding: "12px 23px",
          }}
          onClick={handleSubmit(onSubmit)}
          isLoading={loading?.open}
        >
          保存
        </Button>
      </div>
      <ModalError
        isOpen={isOpen}
        message={
          <p className="w-full text-error--main input-error__message">
            {popupError.replace(/<br\s*\/?>/, "\n")}
          </p>
        }
        onClose={onCloseModalError}
      />
    </div>
  );
}
