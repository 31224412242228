import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import Button from "@/components/atoms/Button/button";
import { formatDate } from "@/shared/format";
import CommentItem from "./comment-item";
import { getLinkMedia } from "@/shared/get";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  useCommentListQuery,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
} from "@/api/comment";
import { COMMENT_TYPE_ENUM } from "@/configs/enum";
import { MAX_LENGTH, PAGINATION_CONSTANT } from "@/configs/constants";
import { handleApiError } from "@/hooks/error";
import { delay, min } from "lodash";
import { MESSAGES } from "@/shared/validation/message";
import { getErrorMessage } from "@/shared/validation/yup";
import { User } from "@/api/type";
import {
  cleanContentComment,
  cutString,
  revertCleanContentComment,
} from "@/shared/transform";
import { ModalConfirm } from "../ModalConfirm/modal-confirm";
import { useDisclosure } from "@chakra-ui/react";
import { useGlobalStore } from "@/states/global.state";
import useToast from "@/hooks/toast";
import { ModalError } from "../ModalError/modal-error";

/** Properties for the ELearningItem component*/
interface CommentProps {
  id: number;
  className?: string;
  commentType?: COMMENT_TYPE_ENUM;
  isAdmin?: boolean;
}

interface CommentItem {
  id: number;
  avatar: string;
  name: string;
  comment: string;
  date: string;
}

export default function CommentElearning({
  id,
  className,
  commentType = COMMENT_TYPE_ENUM.COURSE,
  isAdmin = false,
}: CommentProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [comments, setComments] = useState<CommentItem[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const createCommentMutation = useCreateCommentMutation(commentType, id);
  const updateCommentMutation = useUpdateCommentMutation(commentType, id);
  const deleteCommentMutation = useDeleteCommentMutation();
  const [itemSelecting, setItemSelecting] = useState<CommentItem | null>(null);
  const [itemDeleting, setItemDeleting] = useState<CommentItem | null>(null);
  const { setLoading } = useGlobalStore();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const {
    isOpen: isOpenModalDelete,
    onOpen: openModalDelete,
    onClose: closeModalDelete,
  } = useDisclosure();
  const { showToast } = useToast();

  const { data: commentRes, refetch } = useCommentListQuery(commentType, id, {
    page,
    per_page: PAGINATION_CONSTANT.DEFAULT_PAGE_SIZE,
  });

  const getName = (user: User) => {
    const fullname = `${user.first_name} ${user.last_name}`;
    const department = `${
      user.department?.name ? `${user.department?.name} / ` : ""
    }${user.office?.name ?? ""}`;

    return `${fullname}${department ? `@${department}` : ""}`;
  };

  useEffect(() => {
    if (!commentRes?.comments) return;
    const currentPage = commentRes.paging.current_page;
    const list = commentRes.comments.map(
      ({ id, content, created_at, user }) => ({
        id: id,
        avatar: getLinkMedia(user.avatar?.key),
        name: getName(user),
        comment: content,
        date: formatDate(created_at),
        userId: user.id,
      }),
    );
    if (currentPage === 1) {
      setComments(list);
    } else {
      setComments((prev) => [...prev, ...list]);
    }
    setHasMore(commentRes.paging.total_pages > currentPage);
  }, [commentRes]);

  const onLoadMore = () => {
    setTimeout(() => {
      setPage(page + 1);
    }, 300);
  };

  const onSubmitForm = async () => {
    if (!value)
      return setError(
        getErrorMessage(MESSAGES.MSG_001, {
          field: "コメント",
        }),
      );
    if (value.length > MAX_LENGTH.TEXT)
      return setError(
        getErrorMessage(MESSAGES.MSG_002, {
          max: MAX_LENGTH.TEXT,
        }),
      );
    try {
      setIsLoading(true);
      if (itemSelecting) {
        await updateCommentMutation.mutateAsync({
          id: Number(itemSelecting.id),
          content: cleanContentComment(value),
        });
      } else {
        await createCommentMutation.mutateAsync({
          content: cleanContentComment(value),
        });
      }
      setValue("");
      page === 1 ? refetch() : setPage(1);
      setItemSelecting(null);
      document
        .getElementById("comment_block")!
        .scrollIntoView({ block: "start", behavior: "smooth" });
      delay(
        () =>
          document
            .getElementById("scrollableDiv")!
            .scrollTo({ top: 0, behavior: "smooth" }),
        300,
      );
    } catch (e) {
      const err = handleApiError(e);
      setError(err[0]?.message ?? err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const minHeight = useMemo(() => {
    if (comments.length > 5) {
      return min([comments.length * 167, 832]);
    }
    return "auto";
  }, [comments]);

  const isFirstComment = useMemo(() => {
    return (
      comments.findIndex((comment) => comment.id === itemSelecting?.id) === 0
    );
  }, [comments, itemSelecting]);

  const onEditComment = (comment: CommentItem) => {
    setItemSelecting(comment);
    setValue(revertCleanContentComment(comment?.comment));
    textareaRef.current?.focus();
  };

  const onDeleteComment = (comment: CommentItem) => {
    setItemDeleting(comment);
    openModalDelete();
  };

  const onSubmitDeleteComment = async () => {
    if (!itemDeleting) return;
    try {
      setLoading(true);
      await deleteCommentMutation.mutateAsync({
        commentable_type: commentType,
        commentable_id: id,
        id: Number(itemDeleting?.id),
      });
      refetch();
      showToast({
        title: `${cutString(itemDeleting?.comment)}を削除しました`,
        type: "success",
      });
      setItemDeleting(null);
    } catch (errRes) {
      const err = handleApiError(errRes);
      setError(err[0].message);
    } finally {
      setTimeout(() => {
        closeModalDelete();
        setLoading(false);
      }, 100);
    }
  };

  return (
    <div className={clsx("flex flex-col", className)}>
      <div
        id="scrollableDiv"
        style={{
          height: minHeight,
          overflow: "auto",
        }}
        className="flex gap-6 flex-col"
      >
        <InfiniteScroll
          dataLength={comments.length}
          next={onLoadMore}
          hasMore={hasMore}
          loader={null}
          endMessage={null}
          hasChildren={true}
          scrollableTarget="scrollableDiv"
          className={clsx("flex flex-col pb-[2px]", isFirstComment && "pt-8")}
        >
          {comments.map((item) => (
            <CommentItem
              key={item.id}
              comment={item}
              isAdmin={isAdmin}
              onEditComment={onEditComment}
              onDeleteComment={onDeleteComment}
              itemSelecting={itemSelecting}
              onCancelEditComment={() => {
                setItemSelecting(null);
                setValue("");
              }}
            />
          ))}
        </InfiniteScroll>
      </div>
      <div className="flex gap-2 flex-col px-6 py-5">
        <textarea
          ref={textareaRef}
          className="gap-1 flex w-full h-[200px] border-primary py-3 px-4 rounded-lg resize-none text-black-custom focus-visible:border-primary focus:outline-none placeholder:text-disabled"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={(e) => {
            setValue(e.target.value.trim());
          }}
          placeholder="Aa"
          maxLength={MAX_LENGTH.TEXT}
        />
        {error.length > 0 && (
          <p className="text-error--main input-error__message mb-1">
            {error.replace(/<br\s*\/?>/, "\n")}
          </p>
        )}
        <div className="flex desktop:justify-end mobile:justify-center">
          <Button
            size="sm"
            className={"px-4 py-3 gap-3 rounded-3xl w-[160px]"}
            isDisabled={!value?.trim()}
            isLoading={isLoading}
            onClick={onSubmitForm}
          >
            送信
          </Button>
        </div>
      </div>
      <ModalConfirm
        isOpen={isOpenModalDelete}
        onClose={() => {
          setError("");
          closeModalDelete();
        }}
        header={`${cutString(itemDeleting?.comment)}の削除`}
        message={
          <>
            <p className="body1 text-[#212B36]">
              {cutString(itemDeleting?.comment)}を削除します。
            </p>
            <p className="body1 text-[#212B36]">よろしいですか？</p>
          </>
        }
        onSubmit={onSubmitDeleteComment}
      />
      <ModalError
        message={
          error.length > 0 && (
            <p className="text-error--main input-error__message mb-1">
              {error.replace(/<br\s*\/?>/, "\n")}
            </p>
          )
        }
        isOpen={!!error.length}
        onClose={() => {
          setError("");
        }}
      />
    </div>
  );
}
