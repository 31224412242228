import { useNavigate, useSearchParams } from "react-router-dom";

import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { getRoute } from "@/shared/get/route";
import Button from "@/components/atoms/Button/button";
import EditWhite from "@/assets/icon/edit-white-02.svg";
import QuizzItem from "./quizz-item";
import { QuizzQuestion } from "@/api/type";
import { QUESTION_KIND_ENUM } from "@/configs/enum";
import { useEffect, useState } from "react";
import CopyDoubleWhiteIcon from "@/assets/icon/copy_double_white.svg";
import { useGlobalStore } from "@/states/global.state";
import { useCopyQuizzMutation } from "@/api/quizz";
import useToast from "@/hooks/toast";
import { cutString } from "@/shared/transform";
import { handleApiError } from "@/hooks/error";
import { ModalError } from "@/components/molecules/ModalError/modal-error";

/**
 * Interface: ManagerQuizzQuestionsProps
 *
 * Represents the props for the ManagerQuizzQuestions component.
 *
 * @property id - The id of the quizz.
 * @property questions - The list of questions.
 */
interface ManagerQuizzQuestionsProps {
  id: number;
  title?: string;
  questions: QuizzQuestion[];
}

export default function ManagerQuizzQuestions({
  id,
  questions,
  title,
}: ManagerQuizzQuestionsProps) {
  // #region Data
  const navigate = useNavigate();
  const { setLoading, loading } = useGlobalStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const copyMutation = useCopyQuizzMutation();
  const { showToast } = useToast();
  const [err, setError] = useState("");
  // #endregion

  // #region Events
  const onEditQuizz = () => {
    navigate(getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_EDIT, { id }));
  };

  /** handle copy quizz */
  const onCopyQuizz = async () => {
    setLoading(true);
    try {
      await copyMutation.mutateAsync({ id });
      showToast({
        title: `${cutString(title)}を複製しました`,
        type: "success",
      });
      navigate(getRoute(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_LIST));
    } catch (errRes) {
      const err = handleApiError(errRes);
      setError(err[0].message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  };
  // #endregion

  useEffect(() => {
    if (searchParams.size) {
      setSearchParams({});
    }
  }, []);

  return (
    <div>
      <div className="mb-5 flex items-center justify-between">
        <h5>コンテンツ</h5>
        <div className="flex gap-2">
          <Button
            size="sm"
            isLoading={loading?.open}
            startSlot={<CopyDoubleWhiteIcon />}
            className="w-[140px] h-10 gap-1"
            onClick={onCopyQuizz}
          >
            複製する
          </Button>
          <Button
            size="sm"
            startSlot={<EditWhite className="size-6" />}
            className="w-[140px] h-10 gap-1"
            onClick={onEditQuizz}
          >
            編集する
          </Button>
        </div>
      </div>

      <div className="shadow-card p-6 bg-white rounded-[20px] flex flex-col gap-10">
        {questions.map((question, index) => (
          <QuizzItem
            key={`questions.${index}`}
            questionNumber={index + 1}
            title={question.title ?? ""}
            kind={question.kind as QUESTION_KIND_ENUM}
            answers={question.quiz_question_answers}
          />
        ))}
      </div>
      <ModalError
        message={
          err.length > 0 && (
            <p className="text-error--main input-error__message mb-1">
              {err.replace(/<br\s*\/?>/, "\n")}
            </p>
          )
        }
        isOpen={!!err.length}
        onClose={() => {
          setError("");
        }}
      />
    </div>
  );
}
