import ThreeDotActionTable from "@/components/molecules/ActionButtonTable/three-dot";
import ThreeDotIcon from "@/assets/icon/three-dot.svg";
import PencilIcon from "@/assets/icon/pencil.svg";
import CloseIcon from "@/assets/icon/close.svg";
import Button from "@/components/atoms/Button/button";
import PlusIcon from "@/assets/icon/plus-02.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ADMIN_ROUTES_CONSTANT, ROUTES_CONSTANT } from "@/configs/constants";
import { getRoute } from "@/shared/get/route";
import { useMemo, useState } from "react";
import { OrderDirection, TDocumentList } from "@/api/type";
import InfiniteScroll from "react-infinite-scroll-component";
import { ModalConfirm } from "@/components/molecules/ModalConfirm/modal-confirm";
import { useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { cutString } from "@/shared/transform";
import useToast from "@/hooks/toast";
import { useGlobalStore } from "@/states/global.state";
import { handleApiError } from "@/hooks/error";
import { useDeleteDocumentMutation } from "@/api/document";
import Spinner from "@/components/atoms/Spinner/spinner";
import { useDeleteVideoMutation } from "@/api/video";
import { isNumeric } from "@/shared/get/check";
import { useAuthStore } from "@/states/auth.state";
import { MESSAGES } from "@/shared/validation/message";
import { ModalError } from "@/components/molecules/ModalError/modal-error";
import ArrowUpIcon from "@/assets/icon/arrow-up.svg";
import clsx from "clsx";
interface FolderRightPanelProps {
  isAdmin: boolean;
  fileItems: TDocumentList[];
  refreshFileList: () => void;
  kind: "document" | "video";
  isFetching?: boolean;
  onNextPage: (type: "folder" | "document" | "video") => void;
  hasMore: boolean;
  totalFile: number;
  changeOrderDirection: () => void;
  orderDirection: OrderDirection;
}

export default function FolderRightPanel({
  isAdmin = true,
  fileItems,
  refreshFileList,
  kind,
  isFetching = false,
  onNextPage,
  hasMore,
  changeOrderDirection,
  orderDirection,
}: FolderRightPanelProps) {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setLoading } = useGlobalStore();
  const [isDesktop, isMinHeight] = useMediaQuery([
    "(min-width: 1024px)",
    "(min-height: 1000px)",
  ]);
  const {
    isOpen: isOpenModalDelete,
    onOpen: openModalDelete,
    onClose: closeModalDelete,
  } = useDisclosure();
  const deleteDocumentMutation = useDeleteDocumentMutation();
  const deleteVideoMutation = useDeleteVideoMutation();
  const [err, setError] = useState("");
  const [dataModal, setDataModal] = useState<TDocumentList>({
    id: 0,
    title: "",
    attachment_attributes: null,
    folder_id: 0,
  });
  const { userInfo } = useAuthStore();
  const menuItems = (document: TDocumentList) => {
    const result = [
      {
        icon: <PencilIcon />,
        text: <span className="body1">編集する</span>,
        onClick: (data: TDocumentList) => {
          let url = "";

          if (isAdmin) {
            url =
              kind === "document"
                ? ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_DOCUMENT_EDIT
                : ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_VIDEO_EDIT;
          } else {
            url =
              kind === "document"
                ? ROUTES_CONSTANT.CONTENT_DOCUMENT_EDIT
                : ROUTES_CONSTANT.CONTENT_VIDEO_EDIT;
          }

          navigate(
            getRoute(url, {
              id: data.id,
              folder_id: data.folder_id,
            }),
          );
        },
      },
    ];
    if (document.creator_id === userInfo?.id || isAdmin) {
      result.push({
        icon: <CloseIcon />,
        text: <span className="body1 text-error--main">削除する</span>,
        onClick: (data: TDocumentList) => {
          setDataModal(data);
          openModalDelete();
        },
      });
    }
    return result;
  };
  const id = useMemo(() => {
    return searchParams.get("id");
  }, [searchParams.get("id")]);

  const onDeleteFile = async () => {
    try {
      setLoading(true);
      if (kind === "document") {
        await deleteDocumentMutation.mutateAsync({
          id: dataModal.id,
          folder_id: dataModal.folder_id,
        });
      }
      if (kind === "video") {
        await deleteVideoMutation.mutateAsync({
          id: dataModal.id,
          folder_id: dataModal.folder_id,
        });
      }

      showToast({
        title: `${cutString(dataModal.title)}を削除しました`,
        type: "success",
      });
      refreshFileList();
      closeModalDelete();
    } catch (errRes) {
      const err = handleApiError(errRes);
      if (
        err?.[0]?.path === "id" ||
        err?.[0]?.path === "base" ||
        err?.[0]?.path === "popup"
      ) {
        closeModalDelete();
        setError(err[0].message);
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  };

  const goToDetail = (data: TDocumentList) => {
    let url = "";

    if (isAdmin) {
      url =
        kind === "document"
          ? ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_DOCUMENT_DETAIL
          : ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_VIDEO_DETAIL;
    } else {
      url =
        kind === "document"
          ? ROUTES_CONSTANT.CONTENT_DOCUMENT_DETAIL
          : ROUTES_CONSTANT.CONTENT_VIDEO_DETAIL;
    }
    navigate(
      getRoute(url, {
        id: data.id,
        folder_id: data.folder_id,
      }),
    );
  };

  return (
    <>
      {id && isNumeric(id) ? (
        <div className="flex justify-end">
          <Button
            size="xs"
            variant="outline"
            buttonCustom={{
              borderRadius: "4px",
              padding: "8px 11px",
            }}
            className="mobile:mb-0 desktop:mb-6"
            startSlot={<PlusIcon />}
            onClick={() => {
              let url = "";

              if (isAdmin) {
                url =
                  kind === "document"
                    ? ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_DOCUMENT_CREATE
                    : ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_VIDEO_CREATE;
              } else {
                url =
                  kind === "document"
                    ? ROUTES_CONSTANT.CONTENT_DOCUMENT_CREATE
                    : ROUTES_CONSTANT.CONTENT_VIDEO_CREATE;
              }

              navigate(getRoute(url, { folder_id: Number(id) }));
            }}
          >
            新規追加
          </Button>
        </div>
      ) : (
        <></>
      )}
      <div
        onClick={changeOrderDirection}
        className="pt-4 pb-6 flex flex-row gap-2 border-b-[1px] border-[#E6E8EA] cursor-pointer items-center"
      >
        <p
          className="subtitle1
 text-[#212B36]"
        >
          タイトル名
        </p>
        <div
          className={clsx(
            "end-icon transition ",
            orderDirection === "asc" && "rotate-180",
          )}
        >
          <ArrowUpIcon />
        </div>
      </div>
      <div className="flex gap-y-6 flex-col overflow-x-hidden" id="list-file">
        {isFetching ? (
          <div
            className={clsx(
              "flex justify-center",
              isDesktop
                ? isMinHeight
                  ? "min-h-[calc(100vh-552px)]"
                  : "min-h-[411px]"
                : "min-h-[173px]",
            )}
          >
            <Spinner
              circleStyles={{
                borderColor: `var(--primary-main-color) transparent transparent transparent`,
              }}
            />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={fileItems.length}
            height={
              isDesktop ? (isMinHeight ? "calc(100vh - 552px)" : 411) : 173
            }
            next={() => onNextPage(kind)}
            hasMore={hasMore}
            loader={null}
            endMessage={null}
            scrollableTarget={"list-file"}
          >
            {fileItems.length === 0 && (
              <p className="w-full gap-2 px-4 bg-white text-center subtitle2">
                {MESSAGES.MSG_010}
              </p>
            )}
            {fileItems.map((file) => (
              <div
                className="flex justify-between gap-2 items-center py-2"
                key={file.id}
              >
                <p
                  className="text-[#212B36] font-bold text-base cursor-pointer underline mb-0 whitespace-pre-wrap break-all"
                  onClick={() => goToDetail(file)}
                >
                  {file.title}
                </p>
                <ThreeDotActionTable
                  menuItems={menuItems(file)}
                  iconBtn={<ThreeDotIcon width={20} height={20} />}
                  data={file}
                />
              </div>
            ))}
          </InfiniteScroll>
        )}
      </div>
      <ModalConfirm
        isOpen={isOpenModalDelete}
        onClose={() => {
          setError("");
          closeModalDelete();
        }}
        header={`${cutString(dataModal?.title)}の削除`}
        message={
          <>
            <p className="body1 text-[#212B36]">
              {cutString(dataModal?.title)}を削除します。
            </p>
            <p className="body1 text-[#212B36]">よろしいですか？</p>
          </>
        }
        onSubmit={onDeleteFile}
      />
      <ModalError
        message={
          err.length > 0 && (
            <p className="text-error--main input-error__message mb-1">
              {err.replace(/<br\s*\/?>/, "\n")}
            </p>
          )
        }
        isOpen={!!err.length}
        onClose={() => {
          setError("");
        }}
      />
    </>
  );
}
