import {
  MutateOptions,
  QueryClient,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DeleteFolderRequestBody,
  DeleteFolderResponseBody,
  DetailFolderRequestBody,
  DetailFolderResponseBody,
  FilterFolderRequestBody,
  FilterFolderResponseBody,
  PageFormFolderRequestBody,
  PageFormFolderResponseBody,
  UpsertFolderRequestBody,
  UpsertFolderResponseBody,
  deleteFolderApi,
  detailFolderApi,
  getPageFormFolderApi,
  listFolderApi,
  upsertFolderApi,
} from "./request";

export const useUpsertFolderMutation = (
  options: MutateOptions<
    UpsertFolderResponseBody,
    unknown,
    UpsertFolderRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: upsertFolderApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const fetchListFolder = async (
  queryClient: QueryClient,
  params?: Partial<FilterFolderRequestBody>,
) => {
  return queryClient.fetchQuery({
    queryKey: ["filter-folder", params],
    queryFn: listFolderApi,
  });
};

export const useListFolderQuery = (
  params: Partial<FilterFolderRequestBody>,
  options: Omit<
    UseQueryOptions<
      FilterFolderResponseBody,
      unknown, // TError,
      FilterFolderResponseBody,
      (string | Partial<FilterFolderRequestBody>)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["filter-folder", params],
    queryFn: listFolderApi,
    ...options,
    staleTime: 0,
    gcTime: 0,
  });
};

export const useDeleteFolderMutation = (
  options: MutateOptions<
    DeleteFolderResponseBody,
    unknown,
    DeleteFolderRequestBody,
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      ...options,
      mutationFn: deleteFolderApi,
      onSuccess: () => {},
    },
    queryClient,
  );
};

export const useDetailFolderQuery = (
  id: DetailFolderRequestBody,
  options: Omit<
    UseQueryOptions<
      DetailFolderResponseBody,
      unknown,
      DetailFolderResponseBody,
      (string | number)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["detail-folder", id.toString()],
    queryFn: () => detailFolderApi(id),
    enabled: !!id,
    ...options,
  });
};
export const usePageFormFolderQuery = (
  data: PageFormFolderRequestBody,
  options: Omit<
    UseQueryOptions<
      PageFormFolderResponseBody,
      unknown,
      PageFormFolderResponseBody,
      (string | number)[]
    >,
    "queryKey"
  > = {},
) => {
  return useQuery({
    queryKey: ["get-page-folder", data.folder_id.toString()],
    queryFn: () => getPageFormFolderApi(data),
    enabled: data.enabled,
    ...options,
  });
};
