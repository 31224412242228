import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import {
  ICreateQuizzForm,
  QuizzQuestion,
  useCreateQuizzForm,
} from "./validation";
import {
  ADMIN_ROUTES_CONSTANT,
  MAX_LENGTH,
  QUESTION_KIND_OPTIONS,
} from "@/configs/constants";
import Button from "@/components/atoms/Button/button";
import PlusIcon from "@/assets/icon/plus-02.svg";
import { ControlledSelect } from "@/components/molecules/ControlledSelect/controlled-select";
import CloseIcon from "@/assets/icon/close.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { FieldArrayWithId, useFieldArray } from "react-hook-form";
import MultiChoiceComponent from "./multi-choice";
import SingleChoiceComponent from "./single-choice";
import { QUESTION_KIND_ENUM } from "@/configs/enum";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useCreateQuizzMutation, useUpdateQuizzMutation } from "@/api/quizz";
import { useGlobalStore } from "@/states/global.state";
import useToast from "@/hooks/toast";
import { cutString } from "@/shared/transform";
import {
  handleApiError,
  mapAPIError,
  scrollToFirstError,
  useScrollToFirstError,
} from "@/hooks/error";
import { ShowQuizzResponseBody } from "@/api/quizz/request";
import { QuizzQuestion as QuizzQuestionRes } from "@/api/type";
import { isNumeric } from "@/shared/get/check";
import { ModalError } from "@/components/molecules/ModalError/modal-error";
import { useDisclosure } from "@chakra-ui/react";
import { ControlledTextArea } from "@/components/molecules/ControlledTextare/controlled-textarea";
import DraggableIcon from "@/assets/icon/dragable.svg";
import { ReactSortable } from "react-sortablejs";
interface CreateQuizzProps {
  id?: number;
  quizzInfo?: ShowQuizzResponseBody;
}

export default function CreateQuizzForm({ id, quizzInfo }: CreateQuizzProps) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorApi, setErrorApi] = useState("");
  const {
    control,
    formState,
    watch,
    handleSubmit,
    setError,
    reset,
    getValues,
    setValue,
    clearErrors,
  } = useCreateQuizzForm();
  useScrollToFirstError(formState);
  const { showToast } = useToast();
  const { loading, setLoading } = useGlobalStore();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });
  const { errors } = formState;
  const createQuizzMutation = useCreateQuizzMutation();
  const updateQuizzMutation = useUpdateQuizzMutation();
  const isSortingRef = useRef(false);
  /**
   * Adds a new question to the list of questions.
   *
   * @returns {void}
   */
  const onAddQuestion = useCallback(() => {
    append({
      _id: uuidv4(),
      title: "",
      kind: null,
      answers: [
        {
          _id: uuidv4(),
          point: null,
          title: "",
        },
      ],
      correct_answers: [],
      correct_answer: undefined,
      point: null,
      answers_deleted: [],
    });
  }, [append]);

  /**
   * Removes a question from the list of questions.
   *
   * @param {number} index - The index of the question to be removed.
   * @returns {void}
   */
  const onRemoveQuestion = useCallback(
    (index: number) => {
      const deleted = watch(`questions_deleted`) ?? [];
      const question = watch(`questions.${index}`);

      if (isNumeric(question._id))
        setValue("questions_deleted", [...deleted, question]);

      remove(index);
    },
    [remove, watch],
  );

  const getPoint = (question: QuizzQuestion) => {
    if (question.kind!.value === QUESTION_KIND_ENUM.FREE_INPUT) return 0;
    if (question.kind!.value === QUESTION_KIND_ENUM.SINGLE_ANSWER)
      return question.point ?? 0;
    return (
      question.answers?.reduce((prev, item) => {
        let point = 0;
        if (
          question.correct_answers?.findIndex((i) => i.value === item._id) !==
          -1
        ) {
          point = item.point as number;
        }
        return prev + Number(point);
      }, 0) ?? 0
    );
  };

  /** transform data */
  const getQuestionAnswers = (question: QuizzQuestion) => {
    if (question.kind!.value === QUESTION_KIND_ENUM.FREE_INPUT) {
      return [
        ...question.answers_deleted.map((i) => {
          return {
            id: Number(i._id),
            _destroy: true,
          };
        }),
      ];
    }
    if (question.kind!.value === QUESTION_KIND_ENUM.MULTI_ANSWER) {
      const answers =
        question.answers?.map((answer) => {
          return {
            id: isNumeric(answer._id) ? Number(answer._id) : undefined,
            point: answer.point ?? 0,
            title: answer.title,
            is_correct:
              question.correct_answers?.findIndex(
                (i) => i.value === answer._id,
              ) !== -1,
          };
        }) ?? [];
      if (quizzInfo && question.answers_deleted.length) {
        return [
          ...answers,
          ...question.answers_deleted.map((i) => {
            return {
              id: Number(i._id),
              _destroy: true,
            };
          }),
        ];
      }
      return answers;
    }
    const answers =
      question.answers?.map((answer) => {
        return {
          id: isNumeric(answer._id) ? Number(answer._id) : undefined,
          point: 0,
          title: answer.title,
          is_correct: question.correct_answer?.value === answer._id,
        };
      }) ?? [];

    return [
      ...answers,
      ...question.answers_deleted.map((i) => {
        return {
          id: Number(i._id),
          _destroy: true,
        };
      }),
    ];
  };

  /** handle when click create/edit button */
  const onSubmit = async (values: ICreateQuizzForm) => {
    try {
      setLoading(true);
      const payload = {
        quizzes: {
          title: values.title,
          pass_point: values.pass_point as number,
          quiz_questions_attributes: [
            ...values.questions.map((question) => {
              return {
                id: isNumeric(question._id) ? Number(question._id) : undefined,
                title: question.title,
                kind: question.kind!.value,
                point: getPoint(question),
                quiz_question_answers_attributes: getQuestionAnswers(question),
              };
            }),
            ...(values.questions_deleted.map((question) => {
              return {
                id: Number(question._id),
                _destroy: true,
              };
            }) ?? []),
          ],
        },
      };

      /** when is edit page */
      if (id) {
        await updateQuizzMutation.mutateAsync({
          id: Number(id),
          quizzes: payload.quizzes,
        });
      } else {
        /** when is create page */
        await createQuizzMutation.mutateAsync(payload);
      }
      /** show message */
      showToast({
        title: `${cutString(values.title)}${
          id ? "を保存しました" : "を作成しました"
        }`,
        type: "success",
      });
      navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_LIST);
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError, (e) => {
        const path = e.path;
        if (e.path === "base" || e.path === "id") {
          setError(path, {
            message: e.message,
          });
        } else if (e.path === "popup") {
          onOpen();
          setErrorApi(e.message);
        } else {
          setError(path, {
            message: e.message,
          });
        }
      });
      scrollToFirstError();
    } finally {
      setLoading(false);
    }
  };

  const onChangeQuestionKind = useCallback(
    (
      nVal: { value: string; label: string },
      oVal: { value: string; label: string },
      index: number,
    ) => {
      const oldQuestionObj = getValues();
      if ((nVal && oVal && oVal.value === nVal.value) || (!nVal && !oVal))
        return;

      if (oldQuestionObj.questions.length) {
        oldQuestionObj.questions[index].answers?.forEach((answer) => {
          if (isNumeric(answer._id)) {
            oldQuestionObj.questions[index].answers_deleted.push(answer);
          }
        });
      } else {
        oldQuestionObj.questions[index].answers = [];
      }

      oldQuestionObj.questions[index].point = null;
      oldQuestionObj.questions[index].correct_answer = undefined;
      oldQuestionObj.questions[index].correct_answers = [];
      oldQuestionObj.questions[index].answers =
        oldQuestionObj.questions[index].kind?.value ===
        QUESTION_KIND_ENUM.FREE_INPUT
          ? []
          : [
              {
                _id: uuidv4(),
                point: null,
                title: "",
              },
            ];
      //
      reset(oldQuestionObj);
    },
    [getValues, reset],
  );

  const getCorrectAnswers = (q: QuizzQuestionRes) => {
    if (q.kind === QUESTION_KIND_ENUM.MULTI_ANSWER) {
      const list: { label: string; value: string }[] = [];
      let index = 1;
      for (const answer of q.quiz_question_answers) {
        if (answer.is_correct) {
          list.push({ label: `項目${index}`, value: String(answer.id) });
        }
        index++;
      }
      return list;
    }
    return [];
  };

  const getCorrectAnswer = (q: QuizzQuestionRes) => {
    if (q.kind === QUESTION_KIND_ENUM.SINGLE_ANSWER) {
      const index = q.quiz_question_answers.findIndex((i) => i.is_correct);
      if (index != -1) {
        return {
          label: `項目${index + 1}`,
          value: String(q.quiz_question_answers[index].id),
        };
      }

      return undefined;
    }
    return undefined;
  };

  const getAnswers = (q: QuizzQuestionRes) => {
    if (q.kind === QUESTION_KIND_ENUM.FREE_INPUT) return [];
    if (!q.quiz_question_answers.length)
      return [
        {
          _id: uuidv4(),
          point: null,
          title: "",
        },
      ];
    return q.quiz_question_answers.map((i) => ({
      _id: String(i.id),
      point: q.kind === QUESTION_KIND_ENUM.SINGLE_ANSWER ? 0 : i.point,
      title: i.title,
    }));
  };

  const updateOrder = async (
    newState: FieldArrayWithId<ICreateQuizzForm, "questions", "id">[],
  ) => {
    if (!isSortingRef.current || !getValues) return;
    isSortingRef.current = false;
    setValue(
      "questions",
      newState.map((i) => {
        const index = getValues(`questions`).findIndex(
          (child) => child._id === i._id,
        );
        return getValues(`questions`)[index];
      }),
    );
  };

  useEffect(() => {
    if (quizzInfo) {
      reset({
        title: quizzInfo.title,
        pass_point: quizzInfo.pass_point,
        questions: quizzInfo?.quiz_questions
          ?.sort((a, b) => a.position - b.position)
          ?.map((q) => {
            return {
              _id: String(q.id),
              title: q.title,
              kind: QUESTION_KIND_OPTIONS.find((i) => i.value === q.kind),
              answers: getAnswers(q),
              point: q.kind === QUESTION_KIND_ENUM.FREE_INPUT ? 0 : q.point,
              position: q.position,
              correct_answer: getCorrectAnswer(q),
              correct_answers: getCorrectAnswers(q),
              answers_deleted: [],
            };
          }),
      });
    } else {
      reset({
        title: "",
        pass_point: null,
        questions: [
          {
            _id: uuidv4(),
            title: "",
            kind: null,
            answers: [
              {
                _id: uuidv4(),
                title: "",
                point: null,
              },
            ],
            correct_answers: [],
            correct_answer: null,
            point: null,
            answers_deleted: [],
          },
        ],
        questions_deleted: [],
      });
    }
  }, [quizzInfo]);

  return (
    <>
      <div className="my-8">
        {errors?.base?.message && (
          <p className="w-full text-error--main input-error__message is-invalid">
            {errors.base?.message}
          </p>
        )}
        <h6 className="font-black">基本情報</h6>
        <div className="shadow-card p-6 bg-white rounded-[20px] mt-3 flex flex-col gap-y-6">
          <ControlledInput
            control={control}
            formField="title"
            placeholder="タイトルを入力してください"
            isRequired={true}
            isTrim={true}
            label="タイトル"
            maxLength={MAX_LENGTH.VARCHAR}
            errorMessage={errors.title?.message}
          />
          <div className="flex gap-x-1">
            <ControlledInput
              control={control}
              formField="pass_point"
              placeholder="合格点を入力してください"
              label="合格点"
              isTrim={true}
              isNumber={true}
              isInteger={true}
              autoConvertToHalfWidth
              min={0}
              errorMessage={errors.pass_point?.message}
              className="w-[240px]"
            />
            <span className="body1 text-[#212B36] mt-[46px]">点</span>
          </div>
        </div>
        <ReactSortable
          tag="div"
          list={fields as any}
          draggable=".drag-handle"
          onUpdate={() => {
            isSortingRef.current = true;
            clearErrors && clearErrors("questions");
          }}
          setList={(value) => updateOrder(value as any)}
          className="flex flex-col gap-y-[10px]"
        >
          {fields.map((field, index) => (
            <div
              className="mt-8 drag-handle"
              key={field._id}
              data-id={field._id}
            >
              <div className="flex justify-between">
                <div className="group relative cursor-move">
                  <h6 className="font-black">設問 {index + 1}</h6>
                  <div className="opacity-0 group-hover:opacity-100 absolute top-[2px] left-[-25px]">
                    <DraggableIcon />
                  </div>
                </div>
                {index > 0 ? (
                  <Button
                    variant="danger-outline"
                    buttonCustom={{
                      padding: "6px 8px",
                    }}
                    size="xs"
                    className=""
                    startSlot={<CloseIcon />}
                    onClick={() => onRemoveQuestion(index)}
                  >
                    設問{index + 1}を削除
                  </Button>
                ) : (
                  <></>
                )}
              </div>
              <div className="shadow-card p-6 bg-white rounded-[20px] mt-3 flex flex-col gap-y-6">
                <ControlledTextArea
                  control={control}
                  formField={`questions.${index}.title`}
                  placeholder="質問項目を入力してください"
                  isRequired={true}
                  isTrim={true}
                  label="質問項目"
                  maxLength={MAX_LENGTH.VARCHAR}
                  errorMessage={errors.questions?.[
                    index
                  ]?.title?.message?.toString()}
                  classNameTextarea="max-h-[96px]"
                />
                <ControlledSelect
                  options={QUESTION_KIND_OPTIONS}
                  control={control}
                  formField={`questions.${index}.kind`}
                  placeholder="記述タイプを選択してください"
                  label="記述タイプ"
                  isRequired={true}
                  className="w-[400px]"
                  errorMessage={errors.questions?.[
                    index
                  ]?.kind?.message?.toString()}
                  isClearable={true}
                  onChangeCallback={(nVal, oVal) => {
                    onChangeQuestionKind(nVal, oVal, index);
                  }}
                />
                {watch(`questions.${index}.kind`)?.value ===
                  QUESTION_KIND_ENUM.MULTI_ANSWER && (
                  <MultiChoiceComponent
                    index={index}
                    control={control}
                    errors={errors}
                    watch={watch}
                    setValue={setValue}
                    setError={setError}
                  />
                )}
                {watch(`questions.${index}.kind`)?.value ===
                  QUESTION_KIND_ENUM.SINGLE_ANSWER && (
                  <SingleChoiceComponent
                    index={index}
                    control={control}
                    errors={errors}
                    watch={watch}
                    setValue={setValue}
                    setError={setError}
                  />
                )}
              </div>
            </div>
          ))}
        </ReactSortable>
        <Button
          size="xs"
          className="mt-3 w-[162px]"
          buttonCustom={{
            fontSize: "14px",
            lineHeight: "24px",
            padding: "7px 12px",
          }}
          variant={"outline"}
          startSlot={<PlusIcon width={20} height={20} />}
          onClick={onAddQuestion}
        >
          設問を追加する
        </Button>
        <div className="flex gap-x-4 justify-end mt-[36px]">
          <Button
            variant="info"
            size="sm"
            className="min-w-[128px] w-fit"
            onClick={() =>
              navigate(ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_LIST)
            }
          >
            キャンセル
          </Button>
          <Button
            variant="primary"
            size="sm"
            className="min-w-[128px] w-fit"
            onClick={handleSubmit(onSubmit)}
            isLoading={loading.open}
          >
            {id ? "保存" : "登録"}
          </Button>
        </div>

        <ModalError
          isOpen={isOpen}
          message={
            <p className="w-full text-error--main input-error__message">
              {errorApi}
            </p>
          }
          onClose={() => {
            setErrorApi("");
            onClose();
          }}
        />
      </div>
    </>
  );
}
