import { ErrorIndexObject, formatError } from "@/shared/format";
import { HttpStatusCode } from "axios";
import { isArray, isObject } from "lodash";
import { useEffect } from "react";
import { FormState, UseFormSetError } from "react-hook-form";

const useScrollToFirstError = (form: FormState<any>) => {
  useEffect(() => {
    if (form.isSubmitted && form.errors) {
      const firstErrorElement = document.getElementsByClassName("is-invalid");

      if (firstErrorElement && firstErrorElement[0]) {
        const input = document.querySelector(
          ".is-invalid input",
        ) as HTMLElement | null;
        const inputNotFocus = input?.classList.value.includes(
          "search-content__input",
        );
        if (input && !inputNotFocus) input.focus();

        firstErrorElement[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [form.errors, form.isSubmitted]);
};
const scrollToFirstError = () => {
  setTimeout(() => {
    const firstErrorElement = document.getElementsByClassName("is-invalid");
    if (firstErrorElement && firstErrorElement[0]) {
      firstErrorElement[0].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  });
};

const handleApiError = (error: any, redirect = false) => {
  const regex = new RegExp(/<br\s*\/?>/gm);
  const statusCode = error.response.status;
  switch (statusCode) {
    case HttpStatusCode.InternalServerError: {
      return {
        statusCode,
        redirect: true,
        message: (
          error?.response?.data?.error ||
          error?.response?.data?.error_message ||
          ""
        ).replace(regex, "\n"),
      };
    }
    case HttpStatusCode.Forbidden: {
      return {
        statusCode,
        redirect: true,
        message: (
          error?.response?.data?.error ||
          error?.response?.data?.error_message ||
          ""
        ).replace(regex, "\n"),
      };
    }
    case HttpStatusCode.NotFound: {
      return {
        statusCode,
        redirect,
        message: (
          error?.response?.data?.error ||
          error?.response?.data?.error_message ||
          ""
        ).replace(regex, "\n"),
      };
    }
    case HttpStatusCode.UnprocessableEntity: {
      const obj = error?.response?.data?.error_message;
      return Object.entries(obj).map((item) => {
        if (isArray(item?.[1])) {
          return {
            path: item?.[0],
            message: (item?.[1] as string[])?.at(0)?.replace(regex, "\n"),
          };
        }
        // API return index error object
        if (isObject(item?.[1])) {
          return {
            path: item?.[0],
            message: formatError(item?.[1] as ErrorIndexObject),
          };
        }
      });
    }
    case HttpStatusCode.BadRequest: {
      return {
        statusCode,
        redirect: false,
        message: (
          error?.response?.data?.error ||
          error?.response?.data?.error_message ||
          ""
        ).replace(regex, "\n"),
      };
    }

    default: {
      return error;
    }
  }
};

const mapAPIError = (
  err: any,
  setError: UseFormSetError<any>,
  customSetErr?: (e: any) => void,
) => {
  if (!isArray(err)) return;
  for (const e of err) {
    if (customSetErr) {
      customSetErr(e);
    } else {
      setError(e.path, {
        message: e.message,
      });
    }
  }
  return err;
};

export {
  scrollToFirstError,
  useScrollToFirstError,
  handleApiError,
  mapAPIError,
};
