import Checkbox from "@/components/atoms/Checkbox/checkbox";
import Radio from "@/components/atoms/Radio/radio";
import { QUESTION_KIND_ENUM } from "@/configs/enum";
import clsx from "clsx";
import DOMPurify from "dompurify";

type ExamResultProps = {
  questions: {
    id: number;
    title: string;
    kind: string;
    point: number;
    answer: string;
    user_quiz_question_answers: {
      id: number;
      title: string;
      point: number;
      is_correct: boolean;
      is_answer: boolean;
    }[];
  }[];
};

export const ExamResult = ({ questions }: ExamResultProps) => {
  const renderQuestionItem = (
    question: ExamResultProps["questions"][0],
    index: number,
  ) => {
    return (
      <div className="flex flex-col gap-4" key={question.id}>
        <h6>
          {`Q.${index + 1} `}
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(question.title ?? "", {
                ALLOWED_TAGS: ["a"],
                ALLOWED_ATTR: ["href", "class", "target"],
              }),
            }}
            className="whitespace-break-spaces"
          />
        </h6>
        <div>
          {question.kind === QUESTION_KIND_ENUM.MULTI_ANSWER && (
            <div className={clsx("flex flex-col gap-y-2")}>
              {question.user_quiz_question_answers.map((option) => (
                <Checkbox
                  key={option.id}
                  label={option.title}
                  value={option.id}
                  checked={option.is_answer}
                  isReadOnly={true}
                />
              ))}
            </div>
          )}
          {question.kind === QUESTION_KIND_ENUM.SINGLE_ANSWER && (
            <div className={clsx("flex flex-col gap-y-2")}>
              {question.user_quiz_question_answers.map((option) => (
                <Radio
                  key={option.id}
                  label={option.title}
                  value={option.id}
                  checked={option.is_answer}
                  isReadOnly={true}
                />
              ))}
            </div>
          )}
          {question.kind === QUESTION_KIND_ENUM.FREE_INPUT && (
            <textarea
              className="w-full h-[200px] border-[1px] border-solid border-[#E6E8EA] py-3 px-4 rounded-lg resize-none text-black-custom focus:outline-none"
              placeholder={"Aa"}
              readOnly={true}
              value={question.answer}
            />
            // <ControlledTextArea
            //   formField={`questions.${index}.answers`}
            //   control={control}
            //   maxLength={MAX_LENGTH.TEXT}
            //   isTrim={true}
            //   onChangeCallback={onChange}
            //   isReadOnly={isReadOnly}
            // />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={clsx("flex flex-col gap-10 w-full !bg-gray-200")}>
      {questions?.map((question, index) => {
        return renderQuestionItem(question, index);
      })}
    </div>
  );
};
