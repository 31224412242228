import { QueryFunctionContext } from "@tanstack/react-query";
import {
  Attachment,
  DocumentPayload,
  OrderDirection,
  Paging,
  TDocumentList,
} from "../type";
import { getRoute } from "@/shared/get/route";
import { API_PATH_CONSTANT } from "../constant";
import { request } from "../axios";

export type FilterDocumentRequestBody = {
  page: number;
  per_page: number;
  order_by?: string | null;
  order_direction?: OrderDirection;
  title?: string;
  folder_id?: number | null;
};

export type FilterDocumentResponseBody = {
  documents: TDocumentList[];
  paging: Paging;
};

export const listDocumentApi = (
  context: QueryFunctionContext,
): Promise<FilterDocumentResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_DOCUMENT),
    params: context.queryKey[1],
  });
};

export type UpsertDocumentRequestBody = {
  id?: number;
  folder_id: number;
  documents: DocumentPayload;
  in_admin?: boolean;
};

export type UpsertDocumentResponseBody = {
  id: number;
};

export const upsertDocumentApi = (
  data: UpsertDocumentRequestBody,
): Promise<UpsertDocumentResponseBody> => {
  return request({
    method: data.id ? "PUT" : "POST",
    url: data.id
      ? getRoute(API_PATH_CONSTANT.UPDATE_DOCUMENT, {
          id: data.id,
          folder_id: data.folder_id,
        })
      : getRoute(API_PATH_CONSTANT.CREATE_DOCUMENT, {
          folder_id: data.folder_id,
        }),
    data: {
      documents: data.documents,
    },
  });
};

export type documentVideos = {
  id?: number;
  video?: {
    id: number;
    title: string;
    folder_id: number;
  };
  document?: {
    id: number;
    title: string;
    folder_id: number;
  };
};
export type ShowDocumentResponseBody = {
  id: number;
  title: string;
  content: string;
  attachment: Attachment;
  root_folder?: {
    course_id?: number | null;
    id: number | null;
    parent_id?: number | null;
    user_course_id?: number | null;
  };
  folder?: {
    course_id?: number | null;
    id: number | null;
    parent_id?: number | null;
    user_course_id?: number | null;
    path?: string | null;
  };
  document_videos: documentVideos[];
  creator_id?: number | null;
};
export const showDocumentApi = (
  context: QueryFunctionContext,
): Promise<ShowDocumentResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.SHOW_DOCUMENT, {
      folder_id: context.queryKey[1],
      id: context.queryKey[2],
    }),
  });
};

export type DeleteDocumentRequestBody = {
  id: number;
  folder_id: number;
};

export type DeleteDocumentResponseBody = {
  id: number;
};
export const deleteDocumentApi = (
  data: DeleteDocumentRequestBody,
): Promise<UpsertDocumentResponseBody> => {
  return request({
    method: "DELETE",
    url: getRoute(API_PATH_CONSTANT.UPDATE_DOCUMENT, {
      id: data.id,
      folder_id: data.folder_id,
    }),
  });
};
