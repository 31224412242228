import { DetailFolderResponseBody, FolderItem } from "@/api/folder/request";

/**
 * Cuts a string to a specified maximum length.
 *
 * @param {string} str - The string to be cut.
 * @param {number} [max=80] - The maximum length of the string. Defaults to 80.
 * @returns {string} The cut string.
 */
const cutString = (str?: string | null, max: number = 80): string => {
  if (!str) return "";
  if (str.length > max) {
    return str.substring(0, max) + "⋯";
  }
  return str;
};

/**
 * Converts a string to a number based on the specified type.
 *
 * @param {string} str - The string to be converted.
 * @param {string} [type="integer"] - The type of number to convert to. Defaults to "integer".
 * @returns {string} The converted number as a string.
 */
const convertToNumber = (str: string, type = "integer") => {
  if (type === "integer") {
    return str.replace(/[^0-9]/g, "");
  }
  return str;
};

/**
 * Escapes HTML characters in the input text.
 *
 * @param {string} text - The input text to escape HTML characters from
 * @return {string} The text with HTML characters escaped
 */
const escapeHtml = (text: string): string => {
  return text
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;");
};

/**
 * Cleans the content by escaping HTML characters and converting URLs into clickable links.
 *
 * @param {string | null} content - The content to be cleaned
 * @return {string} The cleaned content with HTML characters escaped and URLs converted to clickable links
 */
const cleanContentComment = (content?: string | null): string => {
  if (!content) {
    return "";
  }

  const pattern = new RegExp(
    "^(http(s)?://.)" + // protocol
      "(www.)?" + // www subdomain (optional)
      "(" +
      "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,})" + // domain name
      "|" +
      "((\\d{1,3}\\.){3}\\d{1,3})" + // OR ip (v4) address
      ")" +
      "((\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)|(\\#[-a-zA-Z\\d_]*))*" + // port and path
      "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-zA-Z\\d_]*)?", // fragment locator
    "gmi",
  );

  let result = escapeHtml(content);

  const matches = result.match(pattern);

  if (!matches) {
    return result;
  }

  matches.forEach((match) => {
    const link = `<a href='${match}' class='text-primary--main' target='_blank'>${match}</a>`;
    result = result.replace(match, link);
  });

  return result;
};

/**
 * Reverts the changes made by cleanContentComment by converting HTML links back to plain URLs.
 *
 * @param {string | null} content - The content containing HTML links to be reverted
 * @return {string} The content with HTML links converted back to plain URLs
 */
const revertCleanContentComment = (content?: string | null): string => {
  if (!content) {
    return "";
  }

  // Pattern to match HTML links created by cleanContentComment
  const pattern =
    /<a href='([^']+)' class='text-primary--main' target='_blank'>[^<]+<\/a>/g;

  // Replace each link with its URL
  return content.replace(pattern, (_match, url) => url);
};

/**
 * Builds an array of breadcrumbs for a given folder by recursively traversing its parent folders.
 *
 * @param {DetailFolderResponseBody | undefined} folder - The current folder to process.
 * @param {DetailFolderResponseBody[]} oldResult - The array of breadcrumbs built so far.
 * @return {DetailFolderResponseBody[]} An array of breadcrumbs from the root folder to the given folder.
 */
const getFoldersBreadcrumbs = (
  folder?: DetailFolderResponseBody,
  oldResult: DetailFolderResponseBody[] = [],
): DetailFolderResponseBody[] | [] => {
  if (!folder) return oldResult;

  const result: DetailFolderResponseBody[] = [
    ...oldResult,
    { id: folder.id, name: folder.name },
  ];

  if (folder.parent) {
    return getFoldersBreadcrumbs(folder.parent, result);
  }

  return result.reverse();
};

/**
 * Finds a folder by its ID, searching through its children if necessary.
 *
 * @param {FolderItem[]} folders - The array of folders to search through.
 * @param {number} id - The ID of the folder to find.
 * @return {FolderItem | undefined} The found folder or undefined if not found.
 */
const findFolderById = (
  folders: FolderItem[],
  id: number,
): FolderItem | undefined => {
  for (const folder of folders) {
    if (folder.id === id) {
      return folder;
    }
    const foundInChilds = findFolderById(folder.childs, id);
    if (foundInChilds) {
      return foundInChilds;
    }
  }
  return undefined;
};

export {
  cutString,
  convertToNumber,
  cleanContentComment,
  escapeHtml,
  getFoldersBreadcrumbs,
  findFolderById,
  revertCleanContentComment,
};
