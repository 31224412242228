export const LABEL = {
  login: {
    email: "メールアドレス",
    password: "パスワード",
  },
  set_password: {
    new_password: "パスワード",
    confirm_password: "パスワード確認",
  },
  set_account: {
    current_password: "現在のパスワード",
    new_password: "新しいパスワード",
    confirm_password: "パスワード確認",
    avatar_url: "",
    first_name: "姓",
    last_name: "名",
    link: "所属",
  },
  my_page: {
    change_email: {
      email: "新しいメールアドレス",
    },
    change_password: {
      current_password: "現在のパスワード",
      new_password: "新しいパスワード",
      password_confirmation: "パスワード確認",
    },
  },
  emoji: {
    code: "",
    name: "名前",
  },
  estimate: {
    staff_count: "スタッフ数",
    size: "サイズ",
    type: "タイプ",
    start_date: "期間",
    end_date: "期間",
  },
  affiliation: {
    office: "支社名",
    department: "事業部名",
  },
  tag: {
    name: "タグ",
  },
  category: {
    name: "カテゴリ",
  },
  admin: {
    user: {
      create: {
        first_name: "姓",
        last_name: "名",
        email: "メールアドレス",
        role_id: "権限",
        department: "所属",
      },
    },
    elearning: {
      create: {
        title: "タイトル",
        category: "カテゴリ",
        office: "支社",
        department: "事業部",
        users: "対象ユーザー",
        content: "コンテンツ",
        document_title: "ドキュメントタイトル",
        upload: "アップロード",
        video_title: "動画タイトル",
        test_title: "テストタイトル",
        role_playing_title: "テーマ",
        compliance_title: "コンプライアンス研修タイトル",
      },
    },
    quizz: {
      create: {
        title: "タイトル",
        pass_point: "合格点",
        question: {
          title: "質問項目",
          kind: "記述タイプ",
        },
        answer: {
          title: "回答選択肢",
          point: "点数",
          correct_answers: "項目番号",
        },
      },
    },
    document: {
      create: {
        title: "タイトル",
        content: "コンテンツ",
        folder_id: "フォルダパス",
      },
    },
    complicate: {
      create: {
        title: "タイトル",
        is_public: "公開設定",
        content: "コンテンツ",
      },
    },
    video: {
      create: {
        title: "タイトル",
        content: "コンテンツ",
      },
    },
    category: {
      create: {
        parent_name: "大カテゴリ名",
        child_name: "中カテゴリ",
      },
    },
  },
  folder: {
    title: "フォルダ名",
    pathName: "フォルダパス",
  },
};
export const MESSAGES = {
  MSG_001: "${field}は必須入力です",
  MSG_002: "${max}文字以内で入力してください。", // maxlength
  MSG_003: "", // minlength
  MSG_004: "${max}より大きい数字を入力してください。", // max
  MSG_005: "${min}より小さい数字を入力してください。", // min
  MSG_006: "数字で入力してください。", // only number
  MSG_007: "メールアドレスの形式ではありません。", // invalid email format
  MSG_008: "メールアドレスが登録されていません。", // email not exist
  MSG_009: "このメールアドレスが既に登録されています。", // email be registed
  MSG_010: "データがありません。", // empty data
  MSG_011:
    "パスワードは半角10文字以上で英語の大文字・英語の小文字・数字・記号を組み合わせて入力してください。", // password format
  MSG_012: "パスワードが一致しません。", // re-password no match with password
  MSG_013: "パスワードが誤っています。", // current password incorrect
  MSG_014: "${max}以下でアップロードしてください。", // max size upload
  MSG_015: "絵文字以外の文字を入力してください。", // no emoji
  MSG_016: "アップロード可能なファイルの拡張子は${type}です", // mimeTypes Upload
  MSG_017: "メールアドレスまたは、パスワードが誤っています。", // login failed
  MSG_027: "カテゴリ名が入力されていません。",
  MSG_029: "タグ名が入力されていません。",
  MSG_033: "点数合計の上限は100点までです。", // The total score for an exam does not exceed 100 points
  MSG_034: "絵文字は入力できません。", // no emoji
  MSG_036: "アップロードが失敗しました。", // upload fail
  MSG_032: "この${field}名が既に登録されています。", // unique name,
  MSG_046:
    "ご利用中のデバイスは動画撮影に対応していません。\nお手数をおかけしますが、ご確認ください。", // not support device,
  MSG_047: "アップロードは必須入力です", // require upload file
  MS_048: "アップロードできるのは1つのファイルのみです。", // only 1 file can be uploaded.
  MSG_049: "指定したフォルダパスが存在しません。", // The specified folder path does not exist.
};
