import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@chakra-ui/react";
import {
  useCreateFavoriteMutation,
  useDeleteFavoriteMutation,
  useFavoriteListQuery,
} from "@/api/favorites";
import { FAVORITE_TYPE_ENUM } from "@/configs/enum";
import EmojiPicker from "@/components/organisms/EmojiPicker/emoji-picker";

import PlusIcon from "@/assets/icon/plus-03.svg";
import { useAuthStore } from "@/states/auth.state";
import { handleApiError } from "@/hooks/error";
import { cutString } from "@/shared/transform";
import { useEmojiListQuery } from "@/api/emoji";
import { getLinkMedia } from "@/shared/get";
import Avatar from "@/components/atoms/Avatar/avatar";
import DOMPurify from "dompurify";
import initEmojies from "@emoji-mart/data";
import PencilIcon from "@/assets/icon/pencil.svg";
import CloseIcon from "@/assets/icon/close.svg";
import CloseWhiteIcon from "@/assets/icon/close_white.svg";
import clsx from "clsx";
export interface CommentItemProps {
  comment: ICommentItem;
  isAdmin?: boolean;
  onEditComment?: (ICommentItem: ICommentItem) => void;
  onDeleteComment?: (ICommentItem: ICommentItem) => void;
  onCancelEditComment?: () => void;
  itemSelecting?: ICommentItem | null;
}

export interface ICommentItem {
  id: number;
  avatar: string;
  name: string;
  date: string;
  comment: string;
  userId?: number;
}

interface Emoji {
  id: string;
  native: string;
  custom?: string | null;
  users: {
    id: number;
    emoji_id: number;
  }[];
}

export default function CommentItem({
  comment,
  isAdmin = false,
  onEditComment = () => {},
  onDeleteComment = () => {},
  onCancelEditComment = () => {},
  itemSelecting = null,
}: CommentItemProps) {
  const { userInfo } = useAuthStore();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [emojies, setEmojies] = useState<Emoji[]>([]);
  const isEditDeleteComment = useMemo(() => {
    return isAdmin || comment?.userId == userInfo?.id;
  }, [isAdmin, comment?.userId, userInfo?.id]);

  const { data: emojiList, refetch: refetchListCustomEmoji } =
    useEmojiListQuery();
  const { data: favoritesRes, refetch } = useFavoriteListQuery(
    FAVORITE_TYPE_ENUM.COMMENT,
    comment.id,
  );
  const createFavoriteMutation = useCreateFavoriteMutation(
    FAVORITE_TYPE_ENUM.COMMENT,
    comment.id,
  );
  const removeFavoriteMutation = useDeleteFavoriteMutation(
    FAVORITE_TYPE_ENUM.COMMENT,
    comment.id,
  );

  const listCustomEmoji = useMemo(() => {
    return (
      emojiList?.emojis.map((emoji) => {
        return {
          id: emoji.code,
          name: emoji.name,
          keywords: ["custom"],
          skins: [{ src: getLinkMedia(emoji.attachment?.key) }],
        };
      }) ?? []
    );
  }, [emojiList]);

  useEffect(() => {
    if (!favoritesRes?.favorites) return;
    const data: Emoji[] = [];
    const favorites = favoritesRes.favorites;
    favorites.forEach((favorite) => {
      const index = data.findIndex((i) => i.id === favorite.code_emoji);
      if (index !== -1) {
        data[index].users = [
          ...data[index].users,
          {
            id: favorite.user.id,
            emoji_id: favorite.id,
          },
        ];
      } else {
        data.push({
          id: String(favorite.code_emoji),
          native: getNativeEmoji(favorite.code_emoji),
          custom: getCustomEmoji(favorite.code_emoji),
          users: [
            {
              id: favorite.user.id,
              emoji_id: favorite.id,
            },
          ],
        });
      }
    });
    setEmojies(data);
  }, [favoritesRes, initEmojies, listCustomEmoji]);

  const getEmojiCode = useCallback((shortcodes: string) => {
    return shortcodes.replace(/^:|:$/g, "");
  }, []);

  const getNativeEmoji = useCallback(
    (shortcodes: string) => {
      const _emojies = (initEmojies as any).emojis;
      const find = _emojies[getEmojiCode(shortcodes)];
      if (!find || !find.skins || !find.skins[0].native) return null;
      return _emojies[getEmojiCode(shortcodes)].skins[0].native;
    },
    [initEmojies],
  );

  const getCustomEmoji = useCallback(
    (shortcodes: string) => {
      const find = listCustomEmoji.find(
        (i) => i.id === getEmojiCode(shortcodes),
      );
      if (find) return find.skins[0].src;
      return null;
    },
    [listCustomEmoji],
  );

  const createFavorite = useCallback(async (code_emoji: string) => {
    setError("");
    try {
      setLoading(true);
      const res = await createFavoriteMutation.mutateAsync({
        code_emoji,
      });
      refetch();
      return res.id;
    } catch (e) {
      const err = handleApiError(e);
      setError(err[0]?.message ?? err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteFavorite = useCallback(async (id: number) => {
    setError("");
    try {
      setLoading(true);
      await removeFavoriteMutation.mutateAsync({
        id,
      });
      refetch();
    } catch (e) {
      const err = handleApiError(e);
      setError(err[0]?.message ?? err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const onSelectEmoji = useCallback(
    async (emoji: any) => {
      if (loading) return;
      const find = emojies.find((item) => item.id === emoji.shortcodes);
      if (find) {
        const isCurrentUser = find.users.find(
          (item) => item.id === userInfo!.id,
        );
        if (isCurrentUser) {
          setEmojies((prev) => {
            const index = prev.findIndex(
              (item) => item.id === emoji.shortcodes,
            );
            if (index !== -1) {
              prev[index].users = prev[index].users.filter(
                (item) => item.id !== userInfo!.id,
              );
            }
            if (prev[index].users.length) {
              return prev;
            } else {
              return prev.filter((item) => item.id !== emoji.shortcodes);
            }
          });
          await deleteFavorite(isCurrentUser.emoji_id);
        } else {
          const id = await createFavorite(emoji.shortcodes);
          if (!id) return;
          setEmojies((prev) => {
            const index = prev.findIndex(
              (item) => item.id === emoji.shortcodes,
            );
            if (index !== -1) {
              prev[index].users = [
                ...prev[index].users.filter((user) => user.id !== userInfo!.id),
                { id: userInfo!.id, emoji_id: id },
              ];
            }
            return prev;
          });
        }
      } else {
        const id = await createFavorite(emoji.shortcodes);
        if (!id) return;
        setEmojies((prev) => [
          ...prev,
          {
            id: emoji.shortcodes,
            native: emoji.native,
            shortcodes: emoji.shortcodes,
            users: [{ id: userInfo!.id, emoji_id: id }],
          },
        ]);
      }
    },
    [emojies, loading],
  );

  const onClickEmoji = useCallback(
    async (emoji_code: string) => {
      const find = emojies.find((item) => item.id === emoji_code);
      if (!find || loading) return;
      const findUser = find.users.find((item) => item.id === userInfo!.id);
      if (findUser) {
        await deleteFavorite(findUser.emoji_id);
        setEmojies((prev) => {
          const index = prev.findIndex((item) => item.id === emoji_code);
          if (index !== -1) {
            prev[index].users = prev[index].users.filter(
              (item) => item.id !== userInfo!.id,
            );
          }
          if (prev[index].users.length) {
            return prev;
          } else {
            return prev.filter((item) => item.id !== emoji_code);
          }
        });
      } else {
        const id = await createFavorite(emoji_code);
        if (!id) return;
        setEmojies((prev) => {
          const index = prev.findIndex((item) => item.id === emoji_code);
          if (index !== -1) {
            prev[index].users = [
              ...prev[index].users.filter((user) => user.id !== userInfo!.id),
              { id: userInfo!.id, emoji_id: id },
            ];
          }
          return prev;
        });
      }
    },
    [loading, userInfo, emojies],
  );

  return (
    <div
      className={clsx(
        "flex flex-col px-6 pt-5 pb-10 gap-6 relative",
        itemSelecting?.id === comment.id
          ? "border-[#73BE1E] border-2 border-solid rounded-xl"
          : "border-b border-[#E6E8EA] border-dashed",
      )}
    >
      {itemSelecting?.id === comment.id && (
        <button
          className="flex flex-row gap-1 py-1 px-3 rounded-t-[8px] absolute top-[-32px] left-3 bg-[#73BE1E] text-white items-center"
          onClick={() => onCancelEditComment()}
        >
          コメント編集中
          <CloseWhiteIcon />
        </button>
      )}
      <div className="flex items-center gap-6">
        <Avatar url={comment.avatar} size={30} />
        <div className="text-base font-bold line-break-anywhere">
          {cutString(comment.name, 100)}
        </div>
      </div>
      <div className="comment line-break-anywhere whitespace-pre-wrap">
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(comment.comment ?? "", {
              ALLOWED_TAGS: ["a"],
              ALLOWED_ATTR: ["href", "class", "target"],
            }),
          }}
        />
      </div>
      <div className="flex justify-between gap-x-4">
        <div className="flex flex-col max-w-[calc(100%-216px)]">
          <div className="flex gap-x-4 items-start">
            <div className="text-sm leading-6 font-normal text-secondary-custom">
              {comment.date}
            </div>

            <div className="flex flex-wrap gap-x-4 gap-y-1">
              {emojies.map((emoji) => (
                <Box
                  as={"button"}
                  key={emoji.id}
                  onClickCapture={() => onClickEmoji(emoji.id)}
                  className="flex items-center gap-x-1 bg-[#f1f9e8] border-[#73be1e] border-solid border-[1px] px-[4px] rounded-[4px]"
                >
                  <span className="w-[24px] h-[24px]">
                    {emoji?.custom ? (
                      <img
                        src={emoji.custom}
                        style={{
                          width: 24,
                          height: 24,
                          objectFit: "contain",
                        }}
                        alt="emoji"
                      />
                    ) : (
                      emoji.native
                    )}
                  </span>
                  <span className="caption1">{emoji.users.length}</span>
                </Box>
              ))}

              <EmojiPicker
                onSelect={onSelectEmoji}
                custom={[
                  {
                    emojis: listCustomEmoji,
                  },
                ]}
                refetchListCustomEmoji={refetchListCustomEmoji}
              >
                <div className="p-[1px] border border-[#E6E8EA] rounded mt-[2px]">
                  <PlusIcon />
                </div>
              </EmojiPicker>
            </div>
          </div>
          {error.length > 0 && (
            <p className="text-error--main input-error__message">
              {error.replace(/<br\s*\/?>/, "\n")}
            </p>
          )}
        </div>
        {isEditDeleteComment && !itemSelecting?.id && (
          <div className="flex items-start gap-x-4 ">
            <button
              className={"flex flex-row gap-1 items-center"}
              onClick={() => onEditComment(comment)}
            >
              <PencilIcon />
              <span className="body1 text-secondary-custom">編集する</span>
            </button>
            <button
              className={"flex flex-row gap-1 items-center"}
              onClick={() => onDeleteComment(comment)}
            >
              <CloseIcon />
              <span className="body1 text-error--main">削除する</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
