import Button from "@/components/atoms/Button/button";
import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import {
  DocumentVideosAttributesForm,
  ICreateContentForm,
  useCreateContentForm,
} from "./validation";
import UploadPdf from "@/components/atoms/UploadDocument/upload-pdf";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_ROUTES_CONSTANT,
  MAX_LENGTH,
  ROUTES_CONSTANT,
} from "@/configs/constants";
import useToast from "@/hooks/toast";
import { useGlobalStore } from "@/states/global.state";
import {
  handleApiError,
  mapAPIError,
  scrollToFirstError,
  useScrollToFirstError,
} from "@/hooks/error";
import { cutString } from "@/shared/transform";
import { useUpsertDocumentMutation } from "@/api/document";
import { useEffect, useMemo, useState } from "react";
import { UploadResponse } from "@/hooks/upload";
import { ControlledRichTextEditor } from "@/components/molecules/ControlledRichTextEditor/controlled-rich-text-editor";
import { ShowDocumentResponseBody } from "@/api/document/request";
import { HttpStatusCode } from "axios";
import { ModalError } from "@/components/molecules/ModalError/modal-error";
import { useDisclosure } from "@chakra-ui/react";
import { findDifferenceLink } from "./utils";
import LinkVideo from "./link-video";
import { SuggestionSearch } from "@/components/molecules/SuggestionSearch/suggestion-search";
import clsx from "clsx";
import { FolderItem } from "@/api/folder/request";

interface CreateDocumentFormProps {
  documentInfo?: ShowDocumentResponseBody;
  folder_id: number;
  forUser?: boolean;
  isAdmin?: boolean;
  path?: string;
}
export default function CreateDocumentForm({
  documentInfo,
  folder_id,
  forUser,
  isAdmin = true,
  path,
}: CreateDocumentFormProps): JSX.Element {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { loading, setLoading } = useGlobalStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorApi, setErrorApi] = useState("");
  const [document_videos, setDocumentVideos] = useState<
    DocumentVideosAttributesForm[]
  >([]);
  const {
    control,
    handleSubmit,
    setError,
    formState,
    setValue,
    reset,
    getValues,
  } = useCreateContentForm();
  useScrollToFirstError(formState);
  const { errors } = formState;
  const upsertDocumentMutation = useUpsertDocumentMutation();
  const [isFocusing, setIsFocusing] = useState(false);
  const [errorFolderId, setErrorFolderId] = useState("");

  const errorMessageFolderId = useMemo(
    () => errors.folder_id?.message?.toString() || errorFolderId,
    [errors.folder_id, errorFolderId],
  );

  const isError = useMemo(
    () => Boolean(errorMessageFolderId),
    [errorMessageFolderId],
  );
  const outValueSearchPath = useMemo(() => {
    return documentInfo?.folder?.id
      ? documentInfo?.folder?.path || ""
      : path || "";
  }, [documentInfo?.folder, path]);
  const [itemSelected, setItemSelected] = useState<FolderItem | undefined>();
  const isShowPath = useMemo(() => {
    return Boolean(documentInfo?.folder?.id && isAdmin);
  }, [documentInfo?.folder?.id, isAdmin]);

  const onSubmit = async (values: ICreateContentForm) => {
    if (errorFolderId) {
      scrollToFirstError();
      return;
    }
    try {
      const videosValid = values?.document_videos_attributes?.filter(
        (video) => !!video?.video_id,
      );
      let documentVideosAttributes = videosValid.map((video) => ({
        video_id: video.video_id?.value,
      }));
      if (documentInfo) {
        const findUnique = findDifferenceLink(
          values?.document_videos_attributes,
          document_videos as any,
          "video",
        )?.filter((video) => !!video?.video_id);
        documentVideosAttributes = findUnique?.map((video) => ({
          id: video?.id,
          video_id: video.video_id?.value,
          _destroy: !!video?.id,
        }));
      }
      setLoading(true);
      await upsertDocumentMutation.mutateAsync({
        id: documentInfo?.id,
        folder_id,
        documents: {
          title: values.title,
          content: values.content,
          attachment_attributes: values.attachment_attributes,
          in_admin: isAdmin,
          document_videos_attributes: documentVideosAttributes,
          folder_id: isShowPath ? Number(values.folder_id) : undefined,
        },
      });
      showToast({
        title: `${cutString(values.title)}${
          documentInfo?.id ? "を保存しました" : "を作成しました"
        }`,
        type: "success",
      });
      navigate(
        `${
          forUser
            ? ROUTES_CONSTANT.CONTENT_DOCUMENT_LIST
            : ADMIN_ROUTES_CONSTANT.MANAGEMENT.CONTENT_DOCUMENT_LIST
        }?id=${isShowPath ? Number(values?.folder_id) : folder_id}&init=1`,
      );
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError, (e) => {
        let path = e.path;
        if (e.path === "base") {
          path = "content";
        } else if (e.path === "id") {
          path = "title";
        } else if (e.path === "popup") {
          onOpen();
          setErrorApi(e.message);
          return;
        }

        setError(path, {
          message: e.message,
        });
      });
      if (
        err?.statusCode &&
        err.statusCode != HttpStatusCode.UnprocessableEntity
      ) {
        setError("title", {
          message: err?.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const onUpload = async (content: UploadResponse | null) => {
    if (!content) {
      setValue("attachment_attributes", null);
      return;
    }
    if (content?._destroy) {
      setValue("attachment_attributes", {
        id: content.id,
        key: content.key,
        name: content.name,
        content_type: content.content_type,
        _destroy: true,
      });
    } else {
      setValue("attachment_attributes", {
        key: content.key,
        name: content.name,
        content_type: content.content_type,
      });
    }
  };

  useEffect(() => {
    if (documentInfo?.id) {
      const documentVideosAttributes = documentInfo?.document_videos?.map(
        (item) => {
          return {
            id: item?.id,
            video_id: {
              label: item?.video?.title,
              value: item?.video?.id,
            },
          };
        },
      );
      setDocumentVideos(
        documentVideosAttributes as DocumentVideosAttributesForm[],
      );
      reset({
        title: documentInfo?.title,
        content: documentInfo?.content,
        attachment_attributes: documentInfo?.attachment,
        document_videos_attributes:
          documentVideosAttributes.length > 0
            ? documentVideosAttributes
            : [{ video_id: null }],
        folder_id: isShowPath ? documentInfo?.folder?.id : undefined,
        isEditFolder: isShowPath,
      });
    } else {
      reset({
        title: "",
        content: null,
        attachment_attributes: null,
        document_videos_attributes: [
          {
            video_id: null,
          },
        ],
        folder_id: undefined,
        isEditFolder: isShowPath,
      });
    }
  }, [documentInfo?.id, isShowPath]);

  return (
    <div className="flex flex-col desktop:gap-y-8 mobile:gap-y-6">
      <div className="shadow-card bg-white p-6 rounded-[20px]">
        <ControlledInput
          control={control}
          formField="title"
          isRequired={true}
          isTrim={true}
          maxLength={MAX_LENGTH.VARCHAR}
          label="タイトル"
          placeholder="タイトルを入力してください"
          errorMessage={errors?.title?.message}
        />
        {isAdmin && (
          <>
            <div className="flex flex-col justify-center text-xs max-md:max-w-full mt-4 mb-1">
              <div className="flex gap-1 max-md:flex-wrap">
                <div
                  className={clsx(
                    isFocusing && !isError
                      ? "text-[var(--primary-main-color)]"
                      : "text-[var(--text-secondary)]",
                  )}
                >
                  フォルダパス
                </div>
                <div className="text-error--main">*</div>
              </div>
            </div>
            <SuggestionSearch
              oldValue={outValueSearchPath}
              kind={"document"}
              isAllowArrowButton={true}
              model="folder"
              labelKey={"path"}
              width="100%"
              readOnly={!documentInfo?.folder?.id}
              isShowIcon={false}
              className="h-[50px]"
              placeholder="/区切りでフォルダパスを入力してください（例：フォルダ名/フォルダ名）"
              itemSelected={itemSelected}
              onSearch={(data) => {
                setErrorFolderId("");
                if (data) {
                  setValue("folder_id", data.id);
                  setItemSelected(data);
                } else {
                  setValue("folder_id", "");
                  setItemSelected(undefined);
                }
              }}
              onFocus={(value: boolean) => {
                setIsFocusing(value);
              }}
              errorMessage={errorMessageFolderId}
              setErrorMessage={(value: string) => {
                setErrorFolderId(value);
              }}
              isBlurOldValue={true}
            />
            {isError && (
              <div className="mt-1 w-full text-error--main input-error__message">
                {errorMessageFolderId}
              </div>
            )}
          </>
        )}
      </div>
      <div>
        <h6 className="font-black mb-3">コンテンツ</h6>
        <div className="shadow-card bg-white p-6 rounded-[20px] mb-3">
          <UploadPdf
            onChange={onUpload}
            isError={
              !!errors.attachment_attributes?.message ||
              !!errors?.content_not_null?.message
            }
            onError={(message) => {
              setError("attachment_attributes", {
                message,
              });
            }}
            onRemove={onUpload}
            attachment={documentInfo?.attachment}
          />
          {errors.attachment_attributes?.message && (
            <div className="mt-1 w-full text-error--main input-error__message is-invalid">
              {errors.attachment_attributes.message}
            </div>
          )}
        </div>
        <>
          <ControlledRichTextEditor
            control={control}
            formField="content"
            errorMessage={
              errors?.content?.message || errors?.content_not_null?.message
            }
            onError={(message) =>
              setError("content", {
                message,
              })
            }
          />
        </>
      </div>
      <LinkVideo
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
      />
      <div className="flex gap-[10px] desktop:justify-end mobile:justify-center">
        <Button
          size="sm"
          variant="info"
          className="min-w-[128px] w-fit"
          buttonCustom={{
            padding: "12px 12px",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          キャンセル
        </Button>
        <Button
          size="sm"
          className="min-w-[128px] w-fit"
          buttonCustom={{
            padding: "12px 12px",
          }}
          isLoading={loading.open}
          onClick={handleSubmit(onSubmit)}
        >
          保存
        </Button>
      </div>
      <ModalError
        isOpen={isOpen}
        message={
          <p className="w-full text-error--main input-error__message">
            {errorApi}
          </p>
        }
        onClose={() => {
          setErrorApi("");
          onClose();
        }}
      />
    </div>
  );
}
