import { request } from "../axios";
import { getRoute } from "@/shared/get/route";
import { API_PATH_CONSTANT } from "../constant";
import {
  OrderDirection,
  Paging,
  QuizQuestionsAttribute,
  QuizzQuestion,
  User,
} from "../type";
import { QueryFunctionContext } from "@tanstack/react-query";
import { FilterQuizzResponseBody } from ".";

export type CreateQuizzRequestBody = {
  quizzes: {
    title: string;
    pass_point: number;
    quiz_questions_attributes: QuizQuestionsAttribute[];
  };
};

export type CreateQuizzResponseBody = {
  id: string | number;
};

export type ShowQuizzResponseBody = {
  id: string | number;
  title: string;
  pass_point: number;
  point: number;
  quiz_questions: QuizzQuestion[];
};

export type DeleteQuizzResponseBody = {
  id: string;
};
export const createQuizzApi = (
  data: CreateQuizzRequestBody,
): Promise<CreateQuizzResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.CREATE_QUIZZ),
    data: data,
  });
};

export type CopyQuizzResponseBody = {
  id: number;
};
export const copyQuizzApi = (
  data: CopyQuizzResponseBody,
): Promise<CreateQuizzResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.COPY_QUIZZ, { id: data.id }),
  });
};

export type UpdateQuizzRequestBody = {
  id: number;
  quizzes: {
    title: string;
    pass_point: number;
    quiz_questions_attributes: QuizQuestionsAttribute[];
  };
};

export type UpdateQuizzResponseBody = {
  id: number;
};
export const updateQuizzApi = (
  data: UpdateQuizzRequestBody,
): Promise<UpdateQuizzResponseBody> => {
  return request({
    method: "PATCH",
    url: getRoute(API_PATH_CONSTANT.UPDATE_QUIZZ, { id: data.id }),
    data: {
      quizzes: data.quizzes,
    },
  });
};
export const listQuizzApi = (
  context: QueryFunctionContext,
): Promise<FilterQuizzResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_QUIZZ),
    params: context.queryKey[1],
  });
};

export const showQuizzApi = (
  context: QueryFunctionContext,
): Promise<ShowQuizzResponseBody> => {
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.SHOW_QUIZZ, { id: context.queryKey[1] }),
  });
};

export const deleteQuizzApi = (
  id: number,
): Promise<DeleteQuizzResponseBody> => {
  return request({
    method: "DELETE",
    url: getRoute(API_PATH_CONSTANT.DELETE_QUIZZ, { id }),
  });
};

export type FilterJoinedUsersQuizzRequestBody = {
  quizz_id: number;
  page: number;
  per_page: number;
  order_by?: string;
  order_direction?: OrderDirection;
};
export type FilterJoinedUsersQuizzResponseBody = {
  users: User[];
  paging: Paging;
};

export const listJoinedUsersQuizzApi = (
  context: QueryFunctionContext,
): Promise<FilterJoinedUsersQuizzResponseBody> => {
  const params = context.queryKey[1] as FilterJoinedUsersQuizzRequestBody;
  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_JOINED_USERS_QUIZZ, {
      id: params.quizz_id,
    }),
    params: context.queryKey[1],
  });
};
