import clsx from "clsx";
import { useMemo, useState } from "react";
import {
  Control,
  Controller,
  FieldValue,
  FieldValues,
  Path,
} from "react-hook-form";

interface ControlledTextAreaProps {
  id?: string;
  name?: string;
  value: string | null;
  className?: string;
  classNameTextarea?: string;
  label?: string;
  note?: string;
  isRequired?: boolean;
  errorMessage?: string;
  placeholder?: string;
  maxLength?: number;
  isTrim?: boolean;
  isReadOnly?: boolean;
  onChangeCallback?: (v: string) => void;
}

interface OwnProps<Type extends FieldValues>
  extends Omit<ControlledTextAreaProps, "value"> {
  control: Control<FieldValue<Type>>;
  formField: Path<FieldValue<Type>>;
}

export const ControlledTextArea = <T extends FieldValues>({
  control,
  formField,
  className,
  label,
  note,
  isRequired,
  errorMessage,
  placeholder = "Aa",
  id,
  name,
  maxLength,
  isTrim = false,
  isReadOnly = false,
  classNameTextarea,
  onChangeCallback,
}: OwnProps<T>) => {
  const [isFocusing, setIsFocus] = useState(false);
  const isError = useMemo(
    () => errorMessage && errorMessage.length > 0,
    [errorMessage],
  );

  return (
    <Controller
      control={control}
      name={formField}
      render={({ field }) => {
        return (
          <div className={className}>
            {label?.length && (
              <div className="flex gap-2 mb-1 justify-between">
                <div className="flex flex-col justify-center text-xs max-md:max-w-full">
                  <div className="flex gap-1 max-md:flex-wrap">
                    <div
                      className={clsx(
                        isFocusing && !isError
                          ? "text-[var(--primary-main-color)]"
                          : "text-[var(--text-secondary)]",
                      )}
                    >
                      {label}
                    </div>
                    {isRequired && <div className="text-error--main">*</div>}
                  </div>
                </div>
                {note && (
                  <div className="text-[var(--text-secondary)] caption1">
                    {note}
                  </div>
                )}
              </div>
            )}

            <textarea
              id={id}
              className={clsx(
                "w-full h-[200px] border-[1px] border-solid border-[#E6E8EA] focus:border-[#73be1e] py-3 px-4 rounded-lg resize-none text-black-custom focus:outline-none",
                classNameTextarea,
              )}
              name={name}
              placeholder={placeholder}
              readOnly={isReadOnly}
              maxLength={maxLength}
              value={field.value ?? ""}
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(value);
                onChangeCallback?.(value);
              }}
              onFocus={() => {
                setIsFocus(true);
              }}
              onBlur={(e) => {
                let val = e.target.value;
                if (isTrim) {
                  val = val.trim();
                }
                onChangeCallback?.(val);
                field.onChange(val);
                setIsFocus(false);
              }}
            />
            {errorMessage?.length && (
              <div className="mt-1 w-full text-error--main input-error__message">
                {errorMessage}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};
