import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup, { getErrorMessage } from "@/shared/validation/yup";
import { MAX_LENGTH } from "@/configs/constants";
import { LABEL, MESSAGES } from "@/shared/validation/message";

export interface IAddFolderForm {
  name: string;
  parent_id?: string | null;
  isEditFolder: boolean;
}

export const useAddFolderForm = () => {
  const validationScheme = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .label("folder.title")
          .noEmoji()
          .max(MAX_LENGTH.VARCHAR)
          .required(),
        parent_id: yup
          .string()
          .label("folder.pathName")
          .nullable()
          .when("isEditFolder", {
            is: (isEditFolder: boolean) => isEditFolder,
            then: (schema) =>
              schema.notOneOf(
                ["", undefined],
                getErrorMessage(MESSAGES.MSG_001, {
                  field: LABEL.folder.pathName,
                }),
              ),
            otherwise: (schema) => schema.optional(),
          })
          .default(null),
        isEditFolder: yup.boolean().default(false),
      }),
    [],
  );

  return useForm<IAddFolderForm>({
    resolver: yupResolver(validationScheme) as any,
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {},
  });
};
