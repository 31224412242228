import { fetchListDocument } from "@/api/document";
import { PAGINATION_CONSTANT } from "@/configs/constants";
import { MESSAGES } from "@/shared/validation/message";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { debounce, min } from "lodash";
import {
  ForwardedRef,
  RefObject,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import List from "react-virtualized/dist/commonjs/List";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import "./suggestion-search.scss";
import { fetchListVideo } from "@/api/video";
import { fetchFilterUser } from "@/api/user";
import Avatar from "@/components/atoms/Avatar/avatar";
import { getLinkMedia } from "@/shared/get";
import { fetchFilterQuizzesList } from "@/api/quizz";
import { fetchFilterComplianceList } from "@/api/compliance";
import {
  fetchFilterELearningList,
  fetchFrontFilterELearningSuggestionList,
} from "@/api/e-learning";
import { isMacOrIOS } from "@/shared/get/check";
import { fetchListFolder } from "@/api/folder";
import { FolderItem } from "@/api/folder/request";
import { scrollToTop } from "@/shared";

interface InputProps {
  oldValue?: string;
  model:
    | "users"
    | "documents"
    | "videos"
    | "quizzes"
    | "compliances"
    | "elearning"
    | "elearning-users-join"
    | "front-elearning"
    | "folder";
  placeholder?: string;
  onSearch: (data?: any) => void;
  width?: string;
  className?: string;
  labelKey: string;
  innerRef?: RefObject<HTMLInputElement>;
  isAdminPage?: boolean;
  isShowIcon?: boolean;
  errorMessage?: string;
  kind?: "document" | "video";
  onFocus?: (value: boolean) => void;
  isAllowArrowButton?: boolean;
  itemSelected?: FolderItem;
  isShowModal?: boolean;
  readOnly?: boolean;
  isShowParent?: boolean;
  setErrorMessage?: (value: string) => void;
  isBlurOldValue?: boolean;
}
export interface SuggestionSearchRef {
  reset: () => void;
}
const SuggestionSearchComponent = (
  {
    oldValue = "",
    model,
    className = "",
    placeholder = "キーワード検索",
    width = "320px",
    labelKey,
    onSearch,
    isAdminPage = true,
    isShowIcon = true,
    errorMessage,
    onFocus,
    itemSelected,
    kind,
    isAllowArrowButton = false,
    readOnly = false,
    isShowParent = false,
    setErrorMessage,
    isBlurOldValue = false,
  }: InputProps,
  ref: ForwardedRef<SuggestionSearchRef>,
) => {
  const queryClient = useQueryClient();
  const [suggestionList, setSuggestionList] = useState<any[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isFocus, setIsFocus] = useState(false);
  const initRef = useRef<HTMLInputElement | null>(null);
  const [oldValueTemp, setOldValueTemp] = useState<string | undefined>();
  const [itemSelectedTemp, setItemSelectedTemp] = useState<
    FolderItem | undefined
  >(itemSelected);
  const [itemSelectedArrow, setItemSelectedArrow] = useState<
    FolderItem | undefined
  >();
  const [indexSelected, setIndexSelected] = useState<number>(-1);
  const isEnterRef = useRef(false);
  useEffect(() => {
    onFocus?.(isFocus);
  }, [isFocus]);

  const debouncedSearch = debounce(async (text: string) => {
    setItemSelectedArrow(undefined);
    if (text.trim() === "") {
      setSuggestionList([]);

      return;
    }
    await getListSuggestion(text.trim());
  }, 500);

  useEffect(() => {
    const index = suggestionList.findIndex(
      (item) => itemSelected?.id === item.id || itemSelected?.id === 0,
    );
    setIndexSelected(-1);
    if (index !== -1) {
      setItemSelectedTemp(suggestionList[index]);
    }
    scrollToTop(`suggestion-item-${0}`);
  }, [suggestionList, isOpen]);

  const changeIndexSelected = (type: "up" | "down") => {
    const newIndexDown =
      indexSelected >= suggestionList.length - 1 ? 0 : indexSelected + 1;
    const newIndexUp =
      indexSelected <= 0 ? suggestionList.length - 1 : indexSelected - 1;
    const newIndex = type === "down" ? newIndexDown : newIndexUp;
    setIndexSelected(newIndex);
    setItemSelectedTemp(suggestionList[newIndex]);
    setItemSelectedArrow(suggestionList[newIndex]);
    scrollToTop(`suggestion-item-${newIndex}`);
  };

  const heightItem = useMemo(() => (model === "users" ? 48 : 40), [model]);

  const heightList = useMemo(
    () => min([suggestionList.length * heightItem + 16, 300]),
    [suggestionList, heightItem],
  );

  const getListDocument = async (title: string) => {
    try {
      const res = await fetchListDocument(queryClient, {
        page: 1,
        per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
        order_by: "updated_at",
        order_direction: "desc",
        title,
      });

      return res.documents;
    } catch {
      return [];
    }
  };

  const getListVideo = async (title: string) => {
    try {
      const res = await fetchListVideo(queryClient, {
        page: 1,
        per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
        order_by: "updated_at",
        order_direction: "desc",
        title,
      });

      return res.videos;
    } catch {
      return [];
    }
  };

  const getListUser = async (fullname: string) => {
    try {
      const res = await fetchFilterUser(queryClient, {
        page: 1,
        per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
        order_by: "updated_at",
        order_direction: "desc",
        fullname,
      });
      return res.users;
    } catch {
      return [];
    }
  };

  const getListQuizzes = async (title: string) => {
    try {
      const res = await fetchFilterQuizzesList(queryClient, {
        page: 1,
        per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
        order_by: "updated_at",
        order_direction: "desc",
        title,
      });
      return res.quizzes;
    } catch {
      return [];
    }
  };

  const getListComplianceTraining = async (title: string) => {
    try {
      const res = await fetchFilterComplianceList(queryClient, {
        page: 1,
        per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
        order_by: "updated_at",
        order_direction: "desc",
        title,
      });
      return res.compliances;
    } catch {
      return [];
    }
  };

  const getListUserJoinELearning = async (fullname: string) => {
    try {
      const res = await fetchFilterUser(queryClient, {
        page: 1,
        per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
        order_by: "updated_at",
        order_direction: "desc",
        fullname,
        status: "done",
      });
      return res.users.map((user) => ({
        ...user,
        fullname: user.first_name + " " + user.last_name,
      }));
    } catch {
      return [];
    }
  };

  const getListELearning = async (title: string) => {
    try {
      const res = await fetchFilterELearningList(queryClient, {
        page: 1,
        per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
        order_by: "updated_at",
        order_direction: "desc",
        title,
      });
      return res.courses;
    } catch {
      return [];
    }
  };

  const getFrontListELearning = async (text_search: string) => {
    try {
      const res = await fetchFrontFilterELearningSuggestionList(queryClient, {
        page: 1,
        per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
        text_search,
      });
      return res.course_suggestions;
    } catch {
      return [];
    }
  };

  const getListFolder = async (path: string) => {
    if (path === "/" && isShowParent) {
      return [
        {
          id: null,
          parent_id: null,
          childs: [],
          path: "/",
        },
      ];
    }
    try {
      const res = await fetchListFolder(queryClient, {
        page: 1,
        per_page: PAGINATION_CONSTANT.MAX_PAGE_SIZE,
        order_by: "name",
        order_direction: "asc",
        kind: kind,
        path: path,
      });
      return res?.folders;
    } catch {
      return [];
    }
  };

  const getListSuggestion = async (title: string) => {
    switch (model) {
      case "documents": {
        const datas = await getListDocument(title);
        setSuggestionList(datas);
        break;
      }

      case "videos": {
        const datas = await getListVideo(title);
        setSuggestionList(datas);
        break;
      }
      case "users": {
        const datas = await getListUser(title);
        setSuggestionList(datas);
        break;
      }
      case "quizzes": {
        const datas = await getListQuizzes(title);
        setSuggestionList(datas);
        break;
      }
      case "compliances": {
        const datas = await getListComplianceTraining(title);
        setSuggestionList(datas);
        break;
      }

      case "elearning": {
        const datas = await getListELearning(title);
        setSuggestionList(datas);
        break;
      }

      case "front-elearning": {
        const datas = await getFrontListELearning(title);
        setSuggestionList(datas);
        break;
      }

      case "elearning-users-join": {
        const datas = await getListUserJoinELearning(title);
        setSuggestionList(datas);
        break;
      }
      case "folder": {
        const datas = await getListFolder(title);
        setSuggestionList(datas || []);
        break;
      }
    }
  };

  const getLabelItem = (item: any, isAvatar = false) => {
    if (model === "users" && labelKey === "fullname") {
      return isAvatar ? (
        <div className="flex gap-2 items-center cursor-pointer">
          <Avatar
            url={getLinkMedia(item.thumbnail?.key || item.avatar?.key)}
            size="sm"
          />
          <span className="body1 text-black-custom break-all">
            {item["first_name"]} {item["last_name"]}
          </span>
        </div>
      ) : (
        `${item?.first_name} ${item?.last_name}`
      );
    }
    return item[labelKey];
  };

  const rowRenderer = (props: any | FolderItem, index = -1) => {
    const item = index > -1 ? props : suggestionList[props.index];
    const key = index > -1 ? props.id : props.key;
    const indexItem = index > -1 ? index : props.index;
    return (
      <div
        key={key}
        style={{
          ...props.style,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
        onClickCapture={() => {
          (initRef.current as any).value = getLabelItem(item);
          onSearch(item);
          getListSuggestion(getLabelItem(item));
        }}
        className={clsx(
          "cursor-pointer [font-size:inherit] w-full hover:bg-[#F1F9E8] text-[#212B36] px-[16px] py-[8px] box-border rounded-[8px] body2 flex items-center",
          itemSelectedTemp?.id === item?.id &&
            isAllowArrowButton &&
            "bg-[#F1F9E8]",
        )}
        id={`suggestion-item-${indexItem}`}
      >
        {getLabelItem(item, true)}
      </div>
    );
  };

  const resetList = () => {
    (initRef.current as any).value = "";
    setSuggestionList([]);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isMacOrIOS()) {
      if (isAllowArrowButton) {
        if (e?.key === "ArrowDown") {
          changeIndexSelected("down");
          e.preventDefault();
        }
        if (e?.key === "ArrowUp") {
          changeIndexSelected("up");
          e.preventDefault();
        }
        if (e?.key === "Enter") {
          const searchItem = itemSelectedArrow?.path
            ? itemSelectedArrow
            : suggestionList.find(
                (item) => item.path === initRef?.current?.value,
              );
          if (searchItem) {
            (initRef?.current as any).value = getLabelItem(searchItem);
            onSearch(searchItem);
            getListSuggestion(getLabelItem(searchItem));
          } else {
            setErrorMessage?.(MESSAGES.MSG_049);
          }
          isEnterRef.current = true;
          initRef?.current?.blur();
        }
      } else if (e?.key === "Enter") {
        onSearch(initRef?.current?.value);
        initRef?.current?.blur();
      }
    }
  };

  useImperativeHandle(ref, () => ({ reset: resetList }));

  useEffect(() => {
    if (oldValue?.length) {
      setOldValueTemp(oldValue);
      getListSuggestion(oldValue);
    }
    return () => {
      setOldValueTemp(undefined);
    };
  }, [oldValue]);
  const isError = useMemo(
    () => errorMessage && errorMessage.length > 0,
    [errorMessage],
  );
  return (
    <div
      style={{
        width: `${width}`,
      }}
    >
      <AutoSizer disableHeight>
        {({ width: w }) => (
          <Popover isOpen={isOpen} gutter={0} initialFocusRef={initRef}>
            <PopoverTrigger>
              <div
                className={clsx(
                  "search-content flex items-center h-[48px] p-3 gap-2 rounded-[12px] bg-white",
                  isFocus ? "border-[#73BE1E]" : "border-[#E6E8EA]",
                  className,
                  isError && "is-invalid input__group",
                )}
                style={{
                  width: w,
                }}
              >
                {isShowIcon && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_3508_11289"
                      style={{
                        maskType: "alpha",
                      }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect
                        width="24"
                        height="24"
                        fill={isAdminPage ? "#637381" : "#73BE1E"}
                      />
                    </mask>
                    <g mask="url(#mask0_3508_11289)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11 2.75C6.58172 2.75 3 6.33172 3 10.75C3 15.1683 6.58172 18.75 11 18.75C12.7204 18.75 14.314 18.2069 15.6187 17.2828L19.293 20.9571C19.6835 21.3476 20.3167 21.3476 20.7072 20.9571C21.0977 20.5666 21.0977 19.9334 20.7072 19.5429L17.0956 15.9313C18.2833 14.5354 19 12.7264 19 10.75C19 6.33172 15.4183 2.75 11 2.75ZM5 10.75C5 7.43629 7.68629 4.75 11 4.75C14.3137 4.75 17 7.43629 17 10.75C17 14.0637 14.3137 16.75 11 16.75C7.68629 16.75 5 14.0637 5 10.75Z"
                        fill={isAdminPage ? "#637381" : "#73BE1E"}
                      />
                    </g>
                  </svg>
                )}
                <input
                  defaultValue={oldValueTemp}
                  ref={initRef}
                  className={clsx("w-full search-content__input body1")}
                  readOnly={readOnly}
                  type={"text"}
                  placeholder={placeholder}
                  onChange={(e) => debouncedSearch(e.target.value)}
                  onFocus={() => {
                    setIsFocus(true);
                    if (!readOnly) {
                      onOpen();
                    }
                  }}
                  onBlur={() => {
                    setIsFocus(false);
                    const newVal = initRef.current?.value?.trim();
                    if (initRef.current && newVal == "" && oldValue != newVal) {
                      onSearch(null);
                    }
                    if (isBlurOldValue && !isError && !isEnterRef.current) {
                      if (initRef.current) {
                        initRef.current.value = itemSelected?.path || "";
                      }
                    }
                    setTimeout(() => {
                      onClose();
                      isEnterRef.current = false;
                    }, 200);
                  }}
                  onKeyDown={(e) => onKeyDown(e)}
                ></input>
              </div>
            </PopoverTrigger>
            <Portal>
              <PopoverContent
                style={{
                  width: w,
                  overflowY: "auto",
                  borderWidth: 0,
                  borderStyle: "solid",
                  backgroundColor: "white",
                  borderRadius: "12px",
                  boxShadow: "0px 0px 20px 0px #0000001A",
                }}
              >
                <div className="w-full p-2">
                  {suggestionList.length === 0 ? (
                    <div className="select-none block [font-size:inherit] w-full  text-[#212B36] px-[16px] py-[8px] box-border rounded-[8px] body2 text-center">
                      {MESSAGES.MSG_010}
                    </div>
                  ) : isAllowArrowButton ? (
                    <div className="flex flex-col gap-2 max-h-[300px] overflow-y-auto">
                      {suggestionList.map((item, index) => (
                        <div
                          style={{
                            width: "100%",
                            padding: `8px ${
                              heightList && heightList < 300 ? "8px" : "0"
                            } 8px 8px`,
                            zIndex: 99,
                          }}
                          key={item.id}
                          id={`suggestion-item-${index}`}
                        >
                          {rowRenderer(item, index)}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <List
                      style={{
                        width: "100%",
                        padding: `8px ${
                          heightList && heightList < 300 ? "8px" : "0"
                        } 8px 8px`,
                        zIndex: 99,
                      }}
                      width={w}
                      height={heightList ?? 300}
                      rowHeight={heightItem}
                      rowCount={suggestionList.length}
                      rowRenderer={rowRenderer}
                    />
                  )}
                </div>
              </PopoverContent>
            </Portal>
          </Popover>
        )}
      </AutoSizer>
    </div>
  );
};

export const SuggestionSearch = forwardRef<SuggestionSearchRef, InputProps>(
  SuggestionSearchComponent,
);
