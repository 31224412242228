import { QueryFunctionContext } from "@tanstack/react-query";

import { request } from "../axios";
import { getRoute } from "@/shared/get/route";
import { API_PATH_CONSTANT } from "../constant";
import { Comment, OrderDirection, Paging } from "../type";
import { COMMENT_TYPE_ENUM } from "@/configs/enum";

export type ListCommentQueryKey = [
  string,
  string,
  string,
  Partial<FilterCommentRequestBody>,
];

export type FilterCommentRequestBody = {
  page: number;
  per_page: number;
  order_by?: string;
  order_direction?: OrderDirection;
};

export type FilterCommentResponseBody = { comments: Comment[]; paging: Paging };

export type CreateCommentResponseBody = {
  id: number;
};

export type CreateCommentRequestBody = {
  content: string;
};

export const listCommentApi = (
  context: QueryFunctionContext<ListCommentQueryKey>,
): Promise<FilterCommentResponseBody> => {
  const [, commentable_type, commentable_id, params] = context.queryKey;

  return request({
    method: "GET",
    url: getRoute(API_PATH_CONSTANT.LIST_COMMENT, {
      commentable_type,
      commentable_id,
    }),
    params,
  });
};

export const createCommentApi = (
  commentable_type: COMMENT_TYPE_ENUM,
  commentable_id: number,
  data: CreateCommentRequestBody,
): Promise<CreateCommentResponseBody> => {
  return request({
    method: "POST",
    url: getRoute(API_PATH_CONSTANT.CREATE_COMMENT, {
      commentable_type,
      commentable_id,
    }),
    data: { comment: data },
  });
};

export type UpdateCommentRequestBody = {
  id: number;
  content: string;
};
export const updateCommentApi = (
  commentable_type: COMMENT_TYPE_ENUM,
  commentable_id: number,
  data: UpdateCommentRequestBody,
): Promise<CreateCommentResponseBody> => {
  return request({
    method: "PUT",
    url: getRoute(API_PATH_CONSTANT.UPDATE_COMMENT, {
      commentable_type,
      commentable_id,
      id: data.id,
    }),
    data: { comment: data },
  });
};

export type DeleteCommentRequestBody = {
  id: number;
  commentable_type: COMMENT_TYPE_ENUM;
  commentable_id: number;
};
export type DeleteCommentResponseBody = {
  id: number;
};
export const deleteCommentApi = (
  data: DeleteCommentRequestBody,
): Promise<DeleteCommentResponseBody> => {
  return request({
    method: "DELETE",
    url: getRoute(API_PATH_CONSTANT.DELETE_COMMENT, {
      commentable_type: data.commentable_type,
      commentable_id: data.commentable_id,
      id: data.id,
    }),
  });
};
