import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import { ICreateQuizzForm } from "./validation";
import { MAX_LENGTH } from "@/configs/constants";
import {
  Control,
  FieldErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import Button from "@/components/atoms/Button/button";
import PlusIcon from "@/assets/icon/plus.svg";
import { ControlledSelect } from "@/components/molecules/ControlledSelect/controlled-select";
import { useCallback, useMemo } from "react";
import CircleCloseIcon from "@/assets/icon/circle-close.svg";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import { isNumeric } from "@/shared/get/check";

interface MultiChoiceComponentProps {
  index: number;
  control: Control<ICreateQuizzForm>;
  errors: FieldErrors<ICreateQuizzForm>;
  watch: UseFormWatch<ICreateQuizzForm>;
  setValue: UseFormSetValue<ICreateQuizzForm>;
  setError: UseFormSetError<ICreateQuizzForm>;
}
export default function MultiChoiceComponent({
  index,
  control,
  errors,
  watch,
  setValue,
}: MultiChoiceComponentProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `questions.${index}.answers`,
  });

  const answers = useWatch({
    name: `questions.${index}.answers`,
    control,
  });

  const onAddAnswer = useCallback(() => {
    append({
      _id: uuidv4(),
      title: "",
      point: null,
    });
  }, [append]);

  const onRemoveAnswer = useCallback(
    (_index: number) => {
      const deleted = watch(`questions.${index}.answers_deleted`);
      const answer = watch(`questions.${index}.answers.${_index}`);
      const correctAnswer = watch(`questions.${index}.correct_answers`);
      if (answer && correctAnswer && correctAnswer.length > 0) {
        setValue(
          `questions.${index}.correct_answers`,
          correctAnswer.filter((i) => i.value !== answer._id),
        );
      }
      if (isNumeric(answer._id))
        setValue(`questions.${index}.answers_deleted`, [...deleted, answer]);

      remove(_index);
    },
    [remove, watch, setValue],
  );

  const correctAnswerOptions = useMemo(() => {
    if (!answers) return [];
    return answers
      .filter((i) => i.title?.length)
      .map((answer, _index) => ({
        value: answer._id,
        label: `項目${_index + 1}`,
      }));
  }, [answers]);

  return (
    <div>
      <p className="subtitle1 font-bold text-[#637381]">回答項目</p>
      <div className="flex flex-col gap-y-[20px] mt-3">
        {fields.map((field, _index) => (
          <div
            className="bg-[#F5F7ED] p-4 rounded-[12px] relative"
            key={field.id}
          >
            {_index > 0 && (
              <CircleCloseIcon
                className="cursor-pointer absolute -right-3 -top-3 w-6 h-6"
                onClick={() => onRemoveAnswer(_index)}
              />
            )}
            <p className="font-bold subtitle2 text-[#637381]">
              項目{_index + 1}
            </p>
            <ControlledInput
              control={control}
              formField={`questions.${index}.answers.${_index}.title`}
              placeholder="回答選択肢を入力してください"
              isTrim={true}
              className="mt-3"
              label="回答選択肢"
              maxLength={MAX_LENGTH.VARCHAR}
              errorMessage={
                errors.questions?.[index]?.answers?.[_index]?.title?.message ??
                ""
              }
            />
            <div className="mt-3 flex items-end">
              <ControlledInput
                control={control}
                formField={`questions.${index}.answers.${_index}.point`}
                placeholder="点数を入力してください"
                label="点数"
                isTrim={true}
                isNumber={true}
                isInteger={true}
                autoConvertToHalfWidth
                min={0}
                errorMessage={
                  errors.questions?.[index]?.answers?.[_index]?.point
                    ?.message ?? ""
                }
                className="w-[240px]"
              />
              <span
                className={clsx(
                  "body1 text-[#212B36] ml-[10px]",
                  (
                    errors.questions?.[index]?.answers?.[
                      _index
                    ]?.point?.message?.toString() ?? ""
                  ).length > 0 && "mb-4",
                )}
              >
                点
              </span>
            </div>
          </div>
        ))}
      </div>
      <Button
        size="xs"
        className="mt-3 w-[136px]"
        buttonCustom={{
          fontSize: "12px",
          lineHeight: "16px",
          padding: "7px 12px",
        }}
        variant={"primary"}
        startSlot={<PlusIcon width={20} height={20} />}
        onClick={onAddAnswer}
      >
        項目を追加する
      </Button>

      <p className="mt-6 subtitle1 font-bold text-[#637381]">正解の項目</p>
      <ControlledSelect
        options={correctAnswerOptions}
        isMulti={true}
        control={control}
        formField={`questions.${index}.correct_answers`}
        placeholder=""
        isRequired={true}
        isClearable={true}
        className="mt-3"
        label="項目番号"
        errorMessage={
          errors.questions?.[index]?.correct_answers?.message?.toString() ?? ""
        }
        // components={{
        //   MultiValueLabel: ({ data }) => {
        //     return <span className="body1">{data.label}</span>;
        //   },
        //   MultiValueRemove: ({ selectProps, data }) => {
        //     const options = (selectProps.value as []).length - 1;
        //     const index = (selectProps.value as []).findIndex(
        //       (i: any) => i.value === (data as any).value,
        //     );
        //     if ((selectProps.value as [])?.length === 0 || index === options) {
        //       return <></>;
        //     }
        //     return <>,</>;
        //   },
        //   MultiValueContainer: ({ children }) => <div>{children}</div>,
        // }}
      />
    </div>
  );
}
