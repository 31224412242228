import Button from "@/components/atoms/Button/button";
import { ModalCommon } from "@/components/atoms/Modal/modal";
import { ControlledInput } from "@/components/molecules/ControlledInput/controlled-input";
import { IAddFolderForm, useAddFolderForm } from "./validation";
import { useGlobalStore } from "@/states/global.state";
import { MAX_LENGTH } from "@/configs/constants";
import useToast from "@/hooks/toast";
import { handleApiError, mapAPIError } from "@/hooks/error";
import { useUpsertFolderMutation } from "@/api/folder";
import { FolderItem, UpsertFolderRequestBody } from "@/api/folder/request";
import { cutString } from "@/shared/transform";
import { useEffect, useMemo, useState } from "react";
import { SuggestionSearch } from "@/components/molecules/SuggestionSearch/suggestion-search";
import clsx from "clsx";

interface ModalProps {
  data: FolderItem;
  isOpen?: boolean;
  isAdmin?: boolean;
  kind: "document" | "video";
  onClose: () => void;
  onSuccess: () => void;
}

export const ModalAddFolder = (props: ModalProps) => {
  const { showToast } = useToast();
  const { loading, setLoading } = useGlobalStore();
  const { control, handleSubmit, setError, setValue, formState, reset } =
    useAddFolderForm();
  const { errors } = formState;
  const [errorParentId, setErrorParentId] = useState("");
  const upsertFolder = useUpsertFolderMutation();
  const [itemSelected, setItemSelected] = useState<FolderItem | undefined>({
    ...props?.data,
    id: Number(props?.data?.parent_id),
  });
  const isShowPath = useMemo(() => {
    return Boolean(props.data?.id && props.isAdmin);
  }, [props.data?.id, props.isAdmin]);

  const onSubmit = async (values: IAddFolderForm) => {
    if (errorParentId) {
      return;
    }
    try {
      setLoading(true);
      const payload: UpsertFolderRequestBody = {
        id: props.data.id,
        folders: {
          name: values.name,
          kind: props.data.kind,
          parent_id: isShowPath
            ? values.parent_id
              ? Number(values.parent_id)
              : null
            : props.data.parent_id,
          in_admin: props?.isAdmin,
        },
      };
      await upsertFolder.mutateAsync(payload);
      showToast({
        title: `${cutString(values.name)}${
          props.data.id ? "を保存しました" : "を作成しました"
        }`,
        type: "success",
      });
      reset({
        name: "",
      });
      props.onSuccess();
    } catch (errRes) {
      const err = handleApiError(errRes);
      mapAPIError(err, setError, (e) => {
        const path = e.path;
        if (path === "base") {
          setError("parent_id", {
            message: e.message,
          });
        } else {
          setError(path, {
            message: e.message,
          });
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    reset({
      name: "",
      parent_id: "",
    });
    setItemSelected(undefined);
    props.onClose();
  };

  useEffect(() => {
    if (props.isOpen) {
      if (props.data.id) {
        let parentId: string | undefined | null = null;
        if (isShowPath) {
          if (!props.data.parent_id) {
            parentId = null;
          } else {
            parentId = props.data.parent_id?.toString();
          }
        }
        reset({
          name: props.data.name,
          parent_id: parentId,
          isEditFolder: isShowPath,
        });
        setItemSelected({
          ...props.data,
          id: Number(props?.data?.parent_id),
        });
      } else {
        reset({
          name: "",
          parent_id: undefined,
          isEditFolder: isShowPath,
        });
        setItemSelected(undefined);
      }
    }
    setErrorParentId("");
  }, [props.isOpen]);

  const [isFocusing, setIsFocusing] = useState(false);

  const errorMessageParentId = useMemo(() => {
    return errors.parent_id?.message?.toString() || errorParentId;
  }, [errors.parent_id, errorParentId]);

  const isError = useMemo(() => {
    return Boolean(errorMessageParentId);
  }, [errorMessageParentId]);

  return (
    <ModalCommon
      id="add-folder-modal"
      onClose={props.onClose}
      isOpen={Boolean(props.isOpen)}
      header={props.data.id ? "フォルダの編集" : "フォルダの追加"}
      variant="addFolder"
      body={
        <div className="flex flex-col">
          <ControlledInput
            control={control}
            formField="name"
            label="フォルダ名"
            maxLength={MAX_LENGTH.VARCHAR}
            isTrim={true}
            isRequired={true}
            placeholder="フォルダ名を入力してください"
            errorMessage={errors.name?.message?.toString()}
          />
          {props.isAdmin && (
            <>
              <div className="flex flex-col justify-center text-xs max-md:max-w-full mt-4 mb-1">
                <div className="flex gap-1 max-md:flex-wrap">
                  <div
                    className={clsx(
                      isFocusing && !isError
                        ? "text-[var(--primary-main-color)]"
                        : "text-[var(--text-secondary)]",
                    )}
                  >
                    フォルダパス
                  </div>
                  <div className="text-error--main">*</div>
                </div>
              </div>
              <SuggestionSearch
                oldValue={props.data?.path}
                kind={props.kind}
                isAllowArrowButton={true}
                model="folder"
                labelKey={"path"}
                width="100%"
                isShowIcon={false}
                isShowModal={true}
                isShowParent={true}
                readOnly={!props.data.id}
                className="h-[50px]"
                placeholder="/区切りでフォルダパスを入力してください（例：フォルダ名/フォルダ名）"
                itemSelected={itemSelected}
                onSearch={(data) => {
                  setErrorParentId("");
                  if (data) {
                    setValue("parent_id", data.id);
                    setItemSelected(data);
                  } else {
                    setValue("parent_id", "");
                    setItemSelected(undefined);
                  }
                }}
                onFocus={(value: boolean) => {
                  setIsFocusing(value);
                }}
                setErrorMessage={(value: string) => {
                  setErrorParentId(value);
                }}
                isBlurOldValue={true}
                errorMessage={errorMessageParentId}
              />
              {isError && (
                <div className="mt-1 w-full text-error--main input-error__message">
                  {errorMessageParentId}
                </div>
              )}
            </>
          )}
        </div>
      }
      footer={
        <div className="flex gap-3">
          <Button
            className="h-10"
            buttonCustom={{
              padding: "8px 16px",
              borderRadius: "8px",
              borderColor: "#E6E8EA",
              color: "#212B36",
              circleStyles: "#212B36",
            }}
            size="sm"
            variant="outline"
            onClick={onClose}
          >
            キャンセル
          </Button>
          <Button
            className="h-10"
            buttonCustom={{
              padding: "8px 16px",
              borderRadius: "8px",
              circleStyles: "var(--primary-main-color)",
              color: "white",
              bg: "var(--primary-main-color)",
            }}
            size="sm"
            isLoading={loading.open}
            onClick={handleSubmit(onSubmit)}
          >
            保存する
          </Button>
        </div>
      }
    />
  );
};
