import { useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import Breadcrumb, {
  BeardcrumbOption,
} from "@/components/atoms/Breadcrumb/breadcrumb";
import { ADMIN_ROUTES_CONSTANT } from "@/configs/constants";
import { ROLE_ENUM } from "@/configs/enum";
import { TabItem, TabView } from "@/components/molecules/TabView/tab-view";
import AnimatedRoute from "@/router/transition.route";
import ManagementQuizzDetail from "@/components/organisms/QuizzManagement/detail";
import ManagementQuizzUserList from "@/components/organisms/QuizzManagement/user-joined-list";
import useRole from "@/hooks/role";
import { useShowQuizzQuery } from "@/api/quizz";
import ManagerQuizzQuestionsSkeleton from "@/components/organisms/QuizzManagement/detail/quizz-detail.skeleton";

function DetailQuizzPage(): JSX.Element {
  useRole(true, [ROLE_ENUM.ADMIN_SYSTEM, ROLE_ENUM.CONTENT_MANAGER]);

  // #region Data
  const params = useParams();

  const [searchParams] = useSearchParams();

  const id = parseInt(params.id as string);

  // * If have query then current tab = user
  // * Else current tab = detail
  const [activeIndexTab, setActiveIndexTab] = useState(
    searchParams.size ? 1 : 0,
  );

  const { data: quizResponse, isFetching } = useShowQuizzQuery(id);

  const questions = useMemo(() => {
    return (
      quizResponse?.quiz_questions?.sort((a, b) => a.position - b.position) ??
      []
    );
  }, [quizResponse]);

  const breadcrumbs: BeardcrumbOption[] = useMemo(
    () => [
      {
        label: "テスト一覧",
        route: ADMIN_ROUTES_CONSTANT.MANAGEMENT.QUIZZ_LIST,
      },
      {
        label: quizResponse?.title,
      },
    ],
    [quizResponse],
  );

  const listTab: TabItem[] = useMemo(() => {
    return [
      { label: "テストコンテンツ" },
      {
        label: `受講者一覧`,
      },
    ];
  }, []);
  // #endregion

  // #region Events
  const onChangeTabView = (index: number) => {
    setActiveIndexTab(index);
  };
  // #endregion

  return (
    <div className="flex w-full gap-6 flex-col mt-6 mb-16">
      <div className="flex gap-3 flex-col">
        <div className="w-full">
          <Breadcrumb options={breadcrumbs} />
        </div>

        <h4 className="mt-3 font-black">{quizResponse?.title}</h4>
      </div>

      <TabView
        listTab={listTab}
        activeIndex={activeIndexTab}
        onChange={onChangeTabView}
      />

      <div>
        {
          // Tab 1
          activeIndexTab === 0 && (
            <>
              {isFetching ? (
                <ManagerQuizzQuestionsSkeleton />
              ) : (
                <ManagementQuizzDetail
                  id={id}
                  questions={questions}
                  title={quizResponse?.title}
                />
              )}
            </>
          )
        }
        {
          // Tab 2
          activeIndexTab === 1 && <ManagementQuizzUserList id={id} />
        }
      </div>
    </div>
  );
}
const AnimatedDetailQuizzPage = AnimatedRoute(DetailQuizzPage);
export default AnimatedDetailQuizzPage;
