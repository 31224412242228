import { modalAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

const baseStyle = definePartsStyle({
  dialog: {
    borderRadius: "16px",
    boxShadow: "-40px 40px 80px -8px #919EAB3D",
    backgroundColor: "#FFFFFF",
  },
  header: {
    padding: "20px 24px",
    fontWeight: "900",
    fontSize: "18px",
    lineHeight: "28px",
  },
  body: {
    padding: "0 24px",
    whiteSpace: "break-spaces",
  },
  footer: {
    padding: "24px",
  },
});

const variantsRecord = definePartsStyle({
  dialog: {
    borderRadius: "0 0 16px 16px",
  },
  header: {
    padding: "0",
  },
  body: {
    padding: "0",
  },
  footer: {
    padding: "0",
  },
});

const variantsAddFolder = definePartsStyle({
  dialogContainer: {
    zIndex: 998,
  },
  overlay: {
    zIndex: 998,
  },
});

const md = defineStyle({
  maxWidth: "480px",
});

const sixXl = defineStyle({
  maxWidth: "1084",
});

const sizes = {
  md: definePartsStyle({ dialog: md }),
  "6xl": definePartsStyle({ dialog: sixXl }),
};

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: {
    records: variantsRecord,
    addFolder: variantsAddFolder,
  },
});
